import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/'

import Dash from '@/views/Dash'
import Login from '@/views/Login'

import SiteCategoryCreate from '@/views/SiteCategoryCreate'
//import SiteCategoryAll from '@/views/SiteCategoryAll'
//import SiteCategoryShow from '@/views/SiteCategoryShow'

import SiteCategory from '@/views/SiteCategory'
import SiteCategories from '@/views/SiteCategories'
import SiteCategoryEdit from '@/views/SiteCategoryEdit'
import CategoryEdit from '@/views/category/CategoryEdit'

import SitePost from '@/views/SitePost'
import SitePostEdit from '@/views/SitePostEdit'

//import SitePostAll from '@/views/SitePostAll'
import SitePosts from '@/views/SitePosts'

import SitePostCreate from '@/views/SitePostCreate'

import SiteTopics from '@/views/SiteTopics'
import SiteTopicCreate from '@/views/SiteTopicCreate'

import SiteSiteMap from '@/views/site/SiteSiteMap'

import CrmContragents from '@/views/CrmContragents'

import ServiceUsers from '@/views/ServiceUsers'
import ServiceUserCreate from '@/views/ServiceUserCreate'

import Roles from '@/views/service/Roles'

import ServiceFeedbackShow from '@/views/ServiceFeedbackShow'


import ServiceCallbackShow from '@/views/ServiceCallbackShow'
import CallbackView from '@/views/service/callback/CallbackView'

import ServicePriceRequestShow from '@/views/ServicePriceRequestShow'
import Updates from '@/views/service/Updates'
import CreateUpdate from '@/views/service/CreateUpdate'
import ServiceAbout from '@/views/ServiceAbout'


import MailingList from '@/views/MailingList/MailingList'
import MailingListEmails from '@/views/MailingList/MailingListEmails'
import MailingListEmail from '@/views/MailingList/MailingListEmail'


import Telegram from '@/views/service/Telegram'
import Log from '@/views/service/Log'
import APIDoc from '@/views/service/APIDoc'

import Error404 from '@/views/Error404'

const routes = [
  {
    // /search/screens -> /search?q=screens
    path: '/',
    name: 'index',
    redirect: to => {
      if (store.getters.isLoggedIn) {
        return { name: 'dash'}
      }else{
        return { name: 'login'}
      }
    },
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/dash',
    name: 'dash',
    component: Dash
  },

  {
    path: '/site/category/create',
    name: 'category.create',
    component: SiteCategoryCreate,
    meta: {
      needAuth: true
    },
  },

  {
    path: '/site/category',
    name: 'category.all',
    component: SiteCategories,
    meta: {
      needAuth: true
    },
  },

  {
    path: '/site/category/:id',
    name: 'category.show',
    component: SiteCategory,
    meta: {
      needAuth: true
    },
    props: true,
    children: [
      {
        path: 'edit',
        name: 'category.edit2',
        component: CategoryEdit,
        props: true,
        meta: {
          needAuth: true
        },
      },
    ]
  },

  // {
  //   path: '/site/category/:id/edit',
  //   name: 'category.edit',
  //   component: SiteCategoryEdit,
  //   meta: {
  //     needAuth: true
  //   },
  //   props: true
  // },

  {
    path: '/site/post',
    name: 'post.all',
    component: SitePosts,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/site/post/create',
    name: 'post.create',
    component: SitePostCreate,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/site/post/:id',
    name: 'post.show',
    component: SitePost,
    meta: {
      needAuth: true
    },
    props: true
  }, //SitePostEdit

  {
    path: '/site/post/:id/edit',
    name: 'post.edit',
    component: SitePostEdit,
    meta: {
      needAuth: true
    },
    props: true
  },

  {
    path: '/site/topic',
    name: 'topic.all',
    component: SiteTopics,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/site/topic/create',
    name: 'topic.create',
    component: SiteTopicCreate,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/site/sitemap',
    name: 'site.sitemap',
    component: SiteSiteMap,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/crm/contragent',
    name: 'crm.all',
    component: CrmContragents,
    meta: {
      needAuth: true
    },
  },

  {
    path: '/service/user',
    name: 'service.users',
    component: ServiceUsers,
    meta: {
      needAuth: true
    },
  },

  {
    path: '/service/user/create',
    name: 'service.user_create',
    component: ServiceUserCreate,
    meta: {
      needAuth: true,
      needAdmin: true
    },
  },

  {
    path: '/service/roles',
    name: 'service.roles',
    component: Roles,
    meta: {
      needAuth: true,
      needAdmin: true
    },
  },

  {
    path: '/service/feedback',
    name: 'service.feedbacks',
    component: ServiceFeedbackShow,
    meta: {
      needAuth: true
    },
  },

  {
    path: '/service/callbacks',
    name: 'service.callbacks',
    component: ServiceCallbackShow,
    meta: {
      needAuth: true
    },
  },

  {
    path: '/service/callback/:hash',
    name: 'service.callback',
    component: CallbackView,
    props: true,
    meta: {
      needAuth: true
    },
  },

  {
    path: '/service/priceRequest',
    name: 'service.price_requests',
    component: ServicePriceRequestShow,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/service/telegram',
    name: 'service.telegram',
    component: Telegram,
    meta: {
      needAuth: true
    }
  },
  {
    path: '/service/log',
    name: 'service.log',
    component: Log,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/service/api',
    name: 'service.api',
    component: APIDoc,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/service/updates',
    name: 'service.updates',
    component: Updates,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/service/update/new',
    name: 'service.update.new',
    component: CreateUpdate,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/about',
    name: 'about',
    component: ServiceAbout,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/mailing_list',
    name: 'mailing_list',
    component: MailingList,
    meta: {
      needAuth: true
    }
  },
  
  {
    path: '/mailing_list/emails',
    name: 'mailing_list_emails',
    component: MailingListEmails,
    meta: {
      needAuth: true
    }
  },

  {
    path: '/mailing_list/emails/email/:id',
    name: 'mailing_list.email',
    component: MailingListEmail,
    props: true,
    meta: {
      needAuth: true
    }
  },


  { 
    path: "/:pathMatch(.*)*", 
    component: Error404 
  }

  // },
  // { ServiceFeedbackShow ServiceCallbackShow
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: '/',
  routes
})


router.beforeEach((to, from, next) => {
  const needAuth = (to.meta.needAuth) ? true : false ;
  let access_token = localStorage.getItem('prime_token');

  if (needAuth && !store.getters.isLoggedIn){
    console.log(to)
    store.commit('setBackUrl', to.fullPath);
    store.dispatch('logout')
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
