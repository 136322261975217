<template>

<nav class="bg-white border-gray-200 dark:bg-gray-800 dark:border-gray-700">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <router-link :to="{name: 'dash'}" class="flex items-center">
        <img src="@/assets/logo_prime_spb.svg" class="h-8 mr-3" alt="PrimeAdminLogo" />
    </router-link>
    <button data-collapse-toggle="navbar-dropdown" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-dropdown" aria-expanded="false">
      <span class="sr-only">Open main menu</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
    </button>
    <div class="hidden w-full md:block md:w-auto" id="navbar-dropdown">
      <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-800 dark:border-gray-700">
        <li>
          <router-link 
           :to="{name: 'dash'}"
           :class="{
             'block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent': $route.name === 'dash', 
             'block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent': $route.name !== 'dash'
           }"
           >
            Даш
          </router-link>
        </li>


        <li>
            <button 
             id="dd1"
             :class="{
               'flex items-center justify-between w-full py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent ddtrig': $route.name === 'post.all' || $route.name === 'category.all', 
               'flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ddtrig': $route.name !== 'post.all' || $route.name !== 'category.all'
             }"
             >
              Сайт 
              <svg class="w-5 h-5 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>

            <!-- Dropdown menu -->
            <div id="ddc1" class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 ddtar">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'category.all'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Категории</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'category.create'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Создать категорию</router-link>
                  </li>
                </ul>
                <ul class="py-1 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'post.all'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Публикации</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'post.create'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Создать публикацию</router-link>
                  </li>
                </ul>
                <ul class="py-1 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'topic.all'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Статьи</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'topic.create'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white hover:rounded-b-lg">Создать статью</router-link>
                  </li>
                </ul>
                <ul class="py-1 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'site.sitemap'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">SiteMap</router-link>
                  </li>
                </ul>
            </div>
        </li>

        <li>
            <button id="dd1" class="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent ddtrig">
              Контрагенты 
              <svg class="w-5 h-5 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>

            <!-- Dropdown menu -->
            <div id="ddc1" class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 ddtar">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'crm.all'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Контрагенты</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'crm.all'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Контрагенты</router-link>
                  </li>
                </ul>
            </div>
        </li>

        <li>
            <button id="emails" class="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent ddtrig">
              Рассылки 
              <svg class="w-5 h-5 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>

            <!-- Dropdown menu -->
            <div id="emailss" class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 ddtar">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'mailing_list'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Почтовые рассылки</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'mailing_list_emails'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Emails</router-link>
                  </li>
                </ul>
            </div>
        </li>


        <li>
            <button id="dd1" class="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent ddtrig">
              Сервис 
              <svg class="w-5 h-5 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>

            <!-- Dropdown menu -->
            <div id="ddc1" class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 ddtar">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'service.users'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Пользователи</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'service.roles'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Роли</router-link>
                  </li>
                </ul>
                <ul class="py-1 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'service.callbacks'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Заявки на звонок</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'service.price_requests'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Запросы прайса</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'service.feedbacks'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Форма обратной связи</router-link>
                  </li>
                </ul>
                <ul class="py-1 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'about'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">О системе</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'service.updates'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Обновления</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'service.api'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">API Doc</router-link>
                  </li>
                </ul>
                <div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
                  <div>Функции администратора</div>
                </div>
                <ul class="py-1 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'service.telegram'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Telegram</router-link>
                  </li>
                </ul>
                <ul class="py-1 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'service.log'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Лог</router-link>
                  </li>
                </ul>
                <ul class="py-1 text-sm text-gray-700 dark:text-gray-400">
                  <li>
                    <router-link :to="{name: 'service.update.new'}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Выпустить обновление</router-link>
                  </li>
                </ul>
                <div class="py-1">
                  <a href="#" @click.prevent="logout()" class="block px-4 py-2 text-sm text-red-500 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-red-500 dark:hover:text-red-500 hover:rounded-b-lg">Выход</a>
                </div>
            </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script>
import {initCollapses} from 'flowbite'
export default {
  name: 'AdminNavbar',

  mounted(){
    this.initdd()
    initCollapses()
  },

  updated(){
  },

  methods: {
    logout(){
      this.$store.dispatch('logout');
      this.$router.push({name: 'login'})
    }
  },

  data() {
    return {

    };
  },

  methods: {
    initdd(){
      let dd_trig = document.getElementsByClassName("ddtrig")
      let dd_tar = document.getElementsByClassName("ddtar")



      if (dd_trig.length != 0 && dd_trig.length == dd_tar.length) {
        for (var i = dd_trig.length - 1; i >= 0; i--) {
          //console.log(dd_tar[i])
          //console.log(dd_trig[i])
          new Dropdown(dd_tar[i], dd_trig[i], {

          });
          
        }
      }
    },
    logout(){
      this.$store.dispatch('logout');
      this.$router.push({name: 'login'})
    }
  }
};
</script>

<style lang="sass" scoped>
</style>
