import store from '@/store'
import router from '@/router'

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = 'https://prime-spb.com/api/v1/';


/**
 * Настройки AXIOS перед отправкой запроса
 */
window.axios.interceptors.request.use(function (config) {
  /**
   * Токен авторизации из LocalStorage
   * @type string
   */
  let access_token = localStorage.getItem('prime_token');
  let app_name = require('../../package.json').name
  let app_v = require('../../package.json').version


  /**
   * Если есть токен, то добавляем заголовок авторизации
   * для axios
   */
  if (access_token) {
    config.headers["Authorization"] = `Bearer ${access_token}`
  }

  if (app_name) {
    config.headers["X-Client"] = app_name
  }

  if (app_v) {
    config.headers["X-Client-Version"] = app_v
  }

  return config;
}, function (error) {});


/**
 * Настройки AXIOS после отправки запроса
 */
window.axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  /**
   * Если пришел 401 ответ
   */
  if (error.response.status === 401) {
    //store.commit('setBackUrl', router.options.history.state.current)
    //store.commit('setBackUrl', router.currentRoute._rawValue.name)
    //
    // new Noty({
    //   type: 'warning',
    //   text: 'Время Вашей сессии истекло.'
    // }).show(); 
    
    store.dispatch('logout')
    router.push({name: 'login', query: {drop: 1}})
  }
  return Promise.reject(error);
});