<template>
  <div class="preloader">
    <div class="preloader__wrapper">
      <div class="preloader__loader">
        <div class="spinner-border text-light" role="status"></div>
      </div>
      <!-- /.preloader__loader -->
      <div class="preloader__status">Секунду, я думаю</div>
      <!-- /.preloader__status -->
    </div>
    <!-- /.preloader__wrapper -->
  </div>
  <!-- /.preloader -->
</template>

<script>
export default {

  name: 'Preloader',

  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
  .preloader {
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(0, 0, 0, .9);
    // .preloader__wrapper
    &__wrapper {
      text-align: center;
    }
    // .preloader__loader
    &__loader {
      text-align: center;
    }
    // .preloader__status
    &__status {
      font-size: 1.2em;
      color: #fff;
      font-weight: bold;
      text-align: center;
    }
  }
</style>
