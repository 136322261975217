<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Почтовая рассылка [emails]</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="p-4 my-4 text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300" role="alert">
      Тут отображены все e-mail, которые пользователи оставляли на сайте (запросы прайс-листа, отправка КП).
    </div>


    <div class="my-4">
      
      <div class="relative overflow-x-auto">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-1.5">
                        ID
                      </th>
                      <th scope="col" class="px-6 py-1.5">
                        Email
                      </th>
                      <th scope="col" class="px-6 py-1.5">
                        Имя
                      </th>
                      <th scope="col" class="px-6 py-1.5">
                        Компания
                      </th>
                      <th scope="col" class="px-6 py-1.5">
                        Статус
                      </th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-if="emails" v-for="(email, index) in emails" :key="index">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{email.id}}
                  </th>
                  <td class="px-6 py-1.5">
                    <router-link :to="{name: 'mailing_list.email', params: {id: email.id}}">{{email.email}}</router-link>
                  </td>
                  <td class="px-6 py-1.5">
                    {{email.name}}
                  </td>
                  <td class="px-6 py-1.5">
                    {{email.company}}
                  </td>
                  <td class="px-6 py-1.5">
                    <span v-if="email.is_active" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-green-600 rounded-full mr-1.5 flex-shrink-0"></span>Активен</span>
                    <span v-else class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-red-600 rounded-full mr-1.5 flex-shrink-0"></span>Не активен</span>
                  </td>
                  <th>
                    <button>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                      </svg>
                    </button>
                  </th>
                </tr>
              </tbody>
          </table>
      </div>

    </div>
    <!-- /.my-4 -->
  </div>
</template>

<script>
export default {

  name: 'MailingListEmails',

  data() {
    return {
      loading: false,
      emails: null
    };
  },

  mounted(){
    this.getAll();
  },


  methods: {
    getAll() {
      this.loading = true
      axios
          .get('emails/emails')
          .then(data=>{
            this.emails = data.data.data.data;
          })
          .catch(err=>{
            console.log(err)
            new Noty({
              type: 'error',
              text: 'Ошибка при получении списка'
            }).show()
          })
          .finally(()=>{
            this.loading = false
          })
    },
  },
};
</script>

<style lang="css" scoped>
</style>
