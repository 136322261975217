<template>
  <div class="flex flex-col grow bg-gray-800 rounded md:rounded-xl p-4 mr-0 min-h-32 max-h-[40rem]">
    <div class="flex flex-row justify-between items-center">
      <h2 class="text-xl mb-2.5">Последние переходы</h2>
      <a href="#" @click.prevent="getStatDatas()">
        <svg :class="{'animate-spin': loading}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
      </a>
    </div>

    <div class="my-2 5">
      <button type="button" :disabled="selected.length == 0" @click="deleteStackVisits()" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1 dark:bg-red-600 dark:disabled:bg-red-800 dark:hover:bg-red-700 dark:disabled:hover:bg-red-800 dark:focus:ring-red-900 dark:disabled:text-gray-500">Удалить выбранные</button>
    </div>
    <!-- /.my-2 5 -->

    <div class="relative overflow-x-auto overflow-y-auto mt-2.5">
      <table class="w-full text-xs text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th></th>
            <th></th>
            <th scope="col" class="px-4 py-1,5">
              Дата
            </th>
            <th scope="col" class="px-4 py-1.5">
              Сессия
            </th>
            <th scope="col" class="px-4 py-1.5">
              URN
            </th>
            <th scope="col" class="px-4 py-1.5">
              ip
            </th>
            <th scope="col" class="px-4 py-1.5">
              Reffer
            </th>
            <th scope="col" class="px-4 py-1.5">
              Ресурс
            </th>
            <th scope="col" class="px-4 py-1.5">
              Фраза
            </th>
            <th scope="col" class="px-4 py-1.5">
              Агент
            </th>
          </tr>
        </thead>
        <tbody v-if="!loading && data">
          <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="(item, index) in data" :key="index">
            <td class="px-2">
              <button :data-dropdown-toggle="'dropdown-'+item.id">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                </svg>
              </button>

              <div :id="'dropdown-'+item.id" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
                  <li>
                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Просмотр</a>
                  </li>
                </ul>
                <div class="py-2">
                  <a href="#" @click.prevent="deleteVisit(item.id)" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-red-500 dark:hover:text-red-400">Удалить</a>
                </div>
              </div>
            </td>
            <td class="px-2">
              <input :id="'checkbox-'+item.id" type="checkbox" :value="item.id" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" @change="toggleSelectedCbx" :data-id="item.id">
            </td>
            <th scope="row" class="px-3 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {{moment(item.dte).fromNow()}}
            </th>
            <td class="px-4 py-1">
              {{item.session}}
            </td>
            <td class="px-4 py-1">
              {{item.target}}
            </td>
            <td class="px-4 py-1">
              {{item.ip}}
            </td>
            <td class="px-4 py-1">
              {{item.reffer}}
            </td>
            <td class="px-4 py-1">
              {{item.utm_source}}
            </td>
            <td class="px-4 py-1">
              {{item.utm_term}}
            </td>
            <td class="px-4 py-1">
              {{item.useragent}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>


  
</template>

<script>
import {HandleError} from '@/helpers/errorHandler.js'
import moment from 'moment'
import {initDropdowns} from 'flowbite';
export default {

  name: 'LastVisitorsCompontnt',

  data() {
    return {
      count: 10,
      loading: true,
      data: null,
      selected: [],
      modal: null,
      modal_item_index: null
    };
  },

  mounted(){
    this.getStatDatas()
    initDropdowns();

    //let el = document.getElementById("visitorModal")

    //this.modal = new Modal(el)
  },

  updated(){
    initDropdowns()
  },

  methods: {
    getStatDatas() {
      this.selected = []
      this.loading = true
      axios
          .get('stat/visits?count='+this.count)
          .then(data=>{
            this.data = data.data.data
          })
          .catch(err=>{

          })
          .finally(()=>{
            this.loading = false
          })
    },

    toggleSelectedCbx(e){
      let id = e.target.value;
      let status = e.target.checked;

      if (status) {
        this.selected.push(id)
      }else{
        const filtered =  this.selected.filter((select) => select !== id);
        this.selected = filtered;
      }
    },

    showModal(index){
      this.modal_item_index = index
    },

    deleteStackVisits(){
      var $that = this;
      this.selected.forEach(function(id) {
        
        axios
          .delete('stat/visit/'+id)
          .then(data=>{
            const filtered =  $that.selected.filter((select) => select !== id);
            $that.selected = filtered;
            $that.getStatDatas();
          })
          .catch(err=>{
            HandleError(err)
          })
      });
    },

    deleteVisit(id){
      axios
          .delete('stat/visit/'+id)
          .then(data=>{
            this.getStatDatas();
          })
          .catch(err=>{
            HandleError(err)
          })
    },

    moment(e){
      moment.locale('ru')
      return moment(e)
    }
  },
};
</script>

<style lang="css" scoped>
</style>
