<template>
   <div class="container mx-auto p-4">
    <div class="flex flex-row justify-between items-center">
      <h1 class="text-3xl text-black dark:text-white">Публикации</h1>
      <router-link :to="{name: 'post.create'}" data-tooltip-target="tooltip-create" class="flex items-center justify-center text-white bg-blue-700 rounded-full w-10 h-10 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
        <svg aria-hidden="true" class="w-6 h-6 transition-transform group-hover:rotate-45" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
        <span class="sr-only">Открыть страницу добавления</span>
      </router-link>
      <div id="tooltip-create" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Создать ge,kbrfwb.
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </div>
    <!-- /.flex -->

    <div class="mt-4">
      <ErrorComponent v-if="error"/>
      <LoadingComponent v-if="loading"/>

      <TransitionGroup class="divide-y divide-gray-200 dark:divide-gray-700" name="list" tag="ul" v-if="posts && !loading">
        <li class="py-3 px-2 sm:py-4 cursor-pointer hover:dark:bg-gray-800 hover:rounded-md" v-for="(post, index) in posts" :key="index">
          <router-link :to="{name: 'post.show', params: {id: post.id}}" class="flex items-center space-x-4 no-underline">
            <div class="flex-shrink-0">
              <img class="w-12 h-12 rounded-full" :src="'https://prime-spb.com/uploads/portfolio_thumb/'+post.thumb_img">
            </div>
            <div class="flex-1 min-w-0">
              <p class="text-md font-medium text-gray-900 truncate dark:text-white">
                {{post.title}}
              </p>
              <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                {{post.description}}
              </p>
              <p class="text-sm text-gray-500 truncate italic dark:text-gray-400 mt-3">
                Опубликован {{moment(post.created_at).fromNow()}}.
              </p>
            </div>
            <div v-if="post.mark_as_deleted" class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
              <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">На удалении</span>
            </div>
            <div v-else class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
              <span v-if="post.is_published" class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Опубликован</span>
              <span v-else class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Не опубликован</span>
            </div>
          </router-link>
        </li>
      </TransitionGroup>

      <div class="flex flex-col items-center">
        <!-- Help text -->
        <span class="text-sm text-gray-700 dark:text-gray-400">
            Показана <span class="font-semibold text-gray-900 dark:text-white">{{currentPage}}</span> стр. из <span class="font-semibold text-gray-900 dark:text-white">{{lastPage}}</span>
        </span>
        <div class="inline-flex mt-2 xs:mt-0">
          <!-- Buttons -->
          <button class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" @click="prevPage()">
              <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
              Назад
          </button>
          <button class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 border-0 border-l border-gray-700 rounded-r hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" @click="nextPage()">
              Далее
              <svg aria-hidden="true" class="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </div>
      </div>
    </div>  
  </div>
  <!-- /.container mx-auto p-4 -->
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent'
import ErrorComponent from '@/components/ErrorComponent'
import moment from 'moment';
export default {

  name: 'SitePosts',

  components: {
    LoadingComponent,
    ErrorComponent
  },

  data() {
    return {
      loading: true,
      error: false,
      posts: [],
      currentPage: 1,
      lastPage: 1
    };
  },

  mounted(){
    // if (this.$route.query.page) {
    //   this.currentPage = this.$route.query.page
    // }
    this.getPosts();
  },

  methods: {
    getPosts(){
      this.loading = true;
      this.error = false;
      this.posts = [];



      axios.get('post'+((this.currentPage == 1) ? '' : '?page='+this.currentPage))
           .then(data=>{
              if (data.data.ok) {
                this.currentPage = data.data.data.current_page;
                this.lastPage = data.data.data.last_page;
                this.posts = data.data.data.data;
              }
            })
           .catch(error=>{
            this.error = true;
            console.log(error);
           })
           .finally(()=>{
            this.loading = false
           })
    },

    nextPage(){
      let next = this.currentPage;
      next++;

      if (next <= this.lastPage) {
        this.currentPage++;
        //this.$router.replace({name: "post.all", query: {page: this.currentPage}})
        this.getPosts()
      }
    },

    prevPage(){
      let prev = this.currentPage;
      prev--;

      if (prev > 0) {
        this.currentPage--;
        // if (this.currentPage > 1) {
        //   this.$router.replace({name: "post.all", query: {page: this.currentPage}})
        // }else{
        //   this.$router.replace({name: "post.all"})
        // }
        
        this.getPosts()
      }
    },

    moment(e){
      moment.locale('ru');
      return moment(e);
    }
  }
};
</script>

<style lang="scss" scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>