<template>
  <div id="drawer-swipe-edit-category" ref="drawer-swipe-edit-category" class="fixed z-40 w-full md:w-4/5 mx-auto overflow-y-auto bg-white border-t border-gray-200 rounded-t-lg dark:border-gray-700 dark:bg-gray-800 transition-transform bottom-0 left-0 right-0 translate-y-full bottom-[60px]">
    <div class="p-4 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700" @click="toggleDw()">
      <span class="absolute w-8 h-1 -translate-x-1/2 bg-gray-300 rounded-lg top-3 left-1/2 dark:bg-gray-600"></span>
      <h5 class="inline-flex items-center text-base text-gray-500 dark:text-gray-400 font-medium">
        Редактирование категории
      </h5>
    </div>

    <div class="min-h-[300px] md:min-h-[650px] p-4" v-if="category">
      
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="flex flex-col">
          <img :src="'https://prime-spb.com/uploads/portfolio_categories/'+category.img" alt="" class="mx-auto w-2/3 md:w-1/3 ">
          <label for="formFile" class="block mb-2 font-medium text-blue-700">Изображение</label>
          <input ref="file-input" class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="formFile" type="file" accept="image/*" @change="setImage">

            <div class="mb-4">
              <vue-cropper
                class="mt-4"
                v-if="imageToCrop"
                ref="cropper"
                :aspect-ratio="1 / 1"
                :src="imageToCrop"
                @crop="cropImage"
              />

              <button v-if="imageToCrop" @click="uploadImageToServer" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-1.5 text-center mr-2 my-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Сохранить</button>
            </div>
            <!-- /.mb-4 -->
        </div>
        
        <div>
          <div class="flex flex-col mb-4">
            <label for="first_name" class="block mb-2 font-medium text-blue-700">Название</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" v-model="category.title">
          </div>

          <div class="flex flex-col mb-4">
            <label for="description" class="block mb-2 font-medium text-blue-700">Описание</label>
            <input type="text" id="description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" v-model="category.description">
          </div>

          <div class="flex flex-col mb-4">
            <label for="meta_description" class="block mb-2 font-medium text-blue-700">CEO-Описание</label>
            <textarea id="meta_description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="category.meta_description"></textarea>
          </div>

          <div class="flex flex-col mb-4">
            <label for="first_name" class="block mb-2 font-medium text-blue-700">Название</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" v-model="category.title">
          </div>
        </div>
        

      </div>
      <!-- /.grid -->

      <button type="button" @click="save()" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 my-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Сохранить</button>

    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { Drawer } from 'flowbite';
import {HandleError} from '@/helpers/errorHandler.js'
export default {

  name: 'CategoryEdit',

  components: {
    VueCropper,
  },

  props: ['id'],

  data() {
    return {
      imageToCrop: null,
      dw: null,
      fd: new FormData(),
      imfd: new FormData(),
      category: null,
      imageLoading: false
    };
  },

  created(){
    this.getCategory()
  },

  mounted(){
    var el = this.$refs['drawer-swipe-edit-category'];

    this.dw = new Drawer(el, {
      placement: 'bottom',
      edge: true,
      edgeOffset: "bottom-[60px]",
      onHide: ()=>{
        var self = this;
        setTimeout(function() {
          self.$router.go(-1)
        }, 200);
        
      }
    });

    

    this.dw.show()
    
  },

  methods: {
    toggleDw() {
      this.dw.toggle()
    },

    getCategory(){
      axios.get('category/'+this.id)
            .then(data=>{
              this.category = data.data.data
            })
            .catch(err=>{
              HandleError(err, 'Не удалось получить категорию: ')
            })
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        new Noty({
          type: 'error',
          text: 'Пожалуйста, выбирете ИЗОБРАЖЕНИЕ.',
          timeout: 1500
        }).show()
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (this.imageToCrop) {
            this.$refs.cropper.replace(event.target.result);
          }
          this.imageToCrop = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        new Noty({
          type: 'error',
          text: 'К сожалению, FileReader API не поддерживается браузером.',
          timeout: 1500
        }).show()
      }
    },

    cropImage() {
      this.$refs.cropper.getCroppedCanvas({width: 500, height: 500})
                        .toBlob(blob => {
                          this.imfd.append("img", blob, 'image.png');
                        });
      console.info('Изображение на загрузку обновлено.');
    },

    save(){
      axios
          .put('category/'+this.id, this.category)
          .then(data=>{
            this.dw.hide()
          })
          .catch(err=>{
            HandleError(err)
          })
    },

    uploadImageToServer(){
      this.imageLoading = true
      axios.post('category/'+this.id+'/image', this.imfd)
            .then(data=>{
              this.imfd = new FormData()
              this.imageToCrop = null
              this.$refs['file-input'].value = null
              this.getCategory()
            })
            .finally(()=>{
              this.imageLoading = false
            })
    }
  },
};
</script>

<style lang="css" scoped>
</style>
