<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Telegram bot</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="bg-gray-800 text-white rounded md:rounded-xl p-4 my-4 flex flex-col">
      <div class="flex flex-row justify-between items-center">
        <h1 class="text-xl">Быстрые действия</h1>
        <button data-popover-target="popover-description-h" data-popover-placement="bottom-end" type="button">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75a4.5 4.5 0 01-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 11-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 016.336-4.486l-3.276 3.276a3.004 3.004 0 002.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.867 19.125h.008v.008h-.008v-.008z" />
          </svg>
        </button>
        <div data-popover id="popover-description-h" role="tooltip" class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
          <div class="p-3 space-y-2">
            <h3 class="font-semibold text-gray-900 dark:text-white">AdminOnly</h3>
            <p>
              Эта функция доступна только администраторам системы.
            </p>
          </div>
          <div data-popper-arrow></div>
        </div>
      </div>
      <div class="mt-4">
        <button @click="sendTestMessage()" type="button" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900">Отправить тестовое сообщение</button>
      </div>
    </div>
    <!-- /.mt-4 mb-4 -->

    <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">

      <div class="bg-gray-800 text-white rounded md:rounded-xl p-4 flex flex-col">
        <div class="flex flex-row justify-between items-center">
          <h1 class="text-xl">Бот по умолчанию</h1>
          <span v-if="!telegram_status" class="flex items-center text-md font-medium text-gray-900 dark:text-white"><span class="flex w-3 h-3 bg-gray-500 rounded-full mr-1.5 flex-shrink-0 animate-pulse"></span>Loading...</span>

          <span v-if="telegram_status && telegram_status.telegram.status == true" class="flex items-center text-md font-medium text-gray-900 dark:text-white"><span class="flex w-3 h-3 bg-green-500 rounded-full mr-1.5 flex-shrink-0"></span>Enabled</span>
          <span v-if="telegram_status && telegram_status.telegram.status == false" class="flex items-center text-md font-medium text-gray-900 dark:text-white"><span class="flex w-3 h-3 bg-yellow-500 rounded-full mr-1.5 flex-shrink-0"></span>Disabled</span>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">id</span>
            <div role="status" class="max-w-sm animate-pulse" v-if="!telegram_status">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 my-2.5"></div>
            </div>
            <div v-else>{{telegram_status.telegram.current.id}}</div>
          </div>

          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">Имя</span>
            <div role="status" class="max-w-sm animate-pulse" v-if="!telegram_status">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 my-2.5"></div>
            </div>
            <div v-else>{{telegram_status.telegram.current.first_name}}</div>
          </div>

          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">Логин</span>
            <div role="status" class="max-w-sm animate-pulse" v-if="!telegram_status">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 my-2.5"></div>
            </div>
            <div v-else>{{telegram_status.telegram.current.username}}</div>
          </div>

          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">Добавление в группы</span>
            <span v-if="!telegram_status" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-gray-500 rounded-full mr-1.5 flex-shrink-0 animate-pulse"></span>Loading...</span>

            <span v-if="telegram_status && telegram_status.telegram.current.can_join_groups == true" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-green-500 rounded-full mr-1.5 flex-shrink-0"></span>Enabled</span>
            <span v-if="telegram_status && telegram_status.telegram.current.can_join_groups == false" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-red-500 rounded-full mr-1.5 flex-shrink-0"></span>Disabled</span>
          </div>

          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">Чтение группы</span>
            <span v-if="!telegram_status" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-gray-500 rounded-full mr-1.5 flex-shrink-0 animate-pulse"></span>Loading...</span>

            <span v-if="telegram_status && telegram_status.telegram.current.can_read_all_group_messages == true" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-green-500 rounded-full mr-1.5 flex-shrink-0"></span>Enabled</span>
            <span v-if="telegram_status && telegram_status.telegram.current.can_read_all_group_messages == false" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-red-500 rounded-full mr-1.5 flex-shrink-0"></span>Disabled</span>
          </div>

          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">Инлайн-запросы</span>
            <span v-if="!telegram_status" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-gray-500 rounded-full mr-1.5 flex-shrink-0 animate-pulse"></span>Loading...</span>

            <span v-if="telegram_status && telegram_status.telegram.current.supports_inline_queries == true" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-green-500 rounded-full mr-1.5 flex-shrink-0"></span>Enabled</span>
            <span v-if="telegram_status && telegram_status.telegram.current.supports_inline_queries == false" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-red-500 rounded-full mr-1.5 flex-shrink-0"></span>Disabled</span>
          </div>
        </div>
        
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 mt-4">
          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">Custom SSL</span>
            <span v-if="!telegram_status" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-gray-500 rounded-full mr-1.5 flex-shrink-0 animate-pulse"></span>Loading...</span>

            <span v-if="telegram_status && telegram_status.telegram.current_status.has_custom_certificate == true" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-green-500 rounded-full mr-1.5 flex-shrink-0"></span>Enabled</span>
            <span v-if="telegram_status && telegram_status.telegram.current_status.has_custom_certificate == false" class="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span class="flex w-2.5 h-2.5 bg-red-500 rounded-full mr-1.5 flex-shrink-0"></span>Disabled</span>
          </div>

          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">В ожидании</span>
            <div role="status" class="max-w-sm animate-pulse" v-if="!telegram_status">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 my-2.5"></div>
            </div>
            <div v-else>{{telegram_status.telegram.current_status.pending_update_count}}</div>
          </div>

          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">Дата последней ошибки</span>
            <div role="status" class="max-w-sm animate-pulse" v-if="!telegram_status">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 my-2.5"></div>
            </div>
            <div v-else>{{momentUnix(telegram_status.telegram.current_status.last_error_date)}}</div>
          </div>

          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">Последняя ошибка</span>
            <div role="status" class="max-w-sm animate-pulse" v-if="!telegram_status">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 my-2.5"></div>
            </div>
            <div v-else>{{telegram_status.telegram.current_status.last_error_message}}</div>
          </div>

          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">Максимум соединений</span>
            <div role="status" class="max-w-sm animate-pulse" v-if="!telegram_status">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 my-2.5"></div>
            </div>
            <div v-else>{{telegram_status.telegram.current_status.max_connections}}</div>
          </div>

          <div class="flex flex-col mt-1.5 text-gray-400">
            <span class="text-sky-700 text-sm font-semibold">ip</span>
            <div role="status" class="max-w-sm animate-pulse" v-if="!telegram_status">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 my-2.5"></div>
            </div>
            <div v-else>{{telegram_status.telegram.current_status.ip_address}}</div>
          </div>

        </div>
      </div>
      <!-- /.bg-gray-800 rounded -->


      <div class="bg-gray-800 text-white rounded md:rounded-xl p-4 flex flex-col h-auto">
        <div class="flex flex-row justify-between items-center">
          <h1 class="text-xl">Отправка сообщения</h1>
          <button data-popover-target="popover-description" data-popover-placement="bottom-end" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75a4.5 4.5 0 01-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 11-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 016.336-4.486l-3.276 3.276a3.004 3.004 0 002.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.867 19.125h.008v.008h-.008v-.008z" />
            </svg>
          </button>
          <div data-popover id="popover-description" role="tooltip" class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
            <div class="p-3 space-y-2">
              <h3 class="font-semibold text-gray-900 dark:text-white">AdminOnly</h3>
              <p>
                Эта функция доступна только администраторам системы.
              </p>
            </div>
            <div data-popper-arrow></div>
          </div>
        </div>

        <div class="mt-4">
          <div class="mb-4">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
            <textarea id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..." v-model="message"></textarea>
          </div>
          <!-- /.mb-4 -->
          <div class="flex items-center mb-4">
              <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" v-model="all" :true-value="true" :false-value="false">
              <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Для всех, независимо от состояния системы</label>
          </div>
          <div class="mb-4">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" @click="send_message()">Send message</button>
          </div>
          <!-- /.mb-4 -->
        </div>
        <!-- /.mt-4 -->
      </div>

      <LastCallbackComponent></LastCallbackComponent>
    </div>
  </div>
</template>

<script>
import {HandleError} from '@/helpers/errorHandler.js'
import {initPopovers} from 'flowbite'
import moment from 'moment'
import LastCallbackComponent from '@/components/telegram/LastCallbackComponent'
export default {

  name: 'Telegram',

  components: {
    LastCallbackComponent
  },

  data() {
    return {
      message: '',
      all: false,
      telegram_status: null
    };
  },

  mounted(){
    initPopovers();
    this.getStatus();
  },


  methods: {
    send_message() {
      axios
          .post('telegram/message', {text: this.message, all: this.all})
          .then(data=>{
            this.message = ''
            new Noty({
              type: 'success',
              text: 'Отправлено'
            }).show();
          })
          .catch(err=>{
            HandleError(err, 'Не удалось отправить сообщение: ')
          })
    },

    getStatus(){
      axios
          .get('telegram/status')
          .then(data=>{
            this.telegram_status = data.data.data
            console.log(data)
          })
          .catch(err=>{
            HandleError(err, 'Не удалось получисть статус: ')
          })
          .finally(()=>{

          })
    },

    sendTestMessage(){
      axios
          .get('telegram/test')
          .then(data=>{
            new Noty({
              type: 'success',
              text: 'Отправлено'
            }).show()
          })
          .catch(err=>{
            HandleError(err, 'Не удалось отправить тестовое сообщение: ')
          })
    },
    moment(e){
      moment.locale('ru');
      return moment(e);
    },

    momentUnix(e){
      moment.locale('ru');
      return moment.unix(e).format('DD.MM.YYYY HH:mm:ss');
    },
  },
};
</script>

<style lang="css" scoped>
</style>
