<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">SiteMap</h1>

    <div class="flex items-center p-4 mt-2 mb-4 text-md text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
      <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span class="sr-only">Info</span>
      <div>
        В файле Sitemap содержится информация о том, как организован контент на сайте (например, веб-страницы, видео и прочие ресурсы). Она помогает Google и другим поисковым системам более точно индексировать ваши материалы. К примеру, в файле Sitemap можно указать, какие разделы сайта наиболее важны, и сообщить нам дополнительную информацию о них (когда страница последний раз обновлялась, существуют ли ее версии на других языках и т. д.). 
      </div>
    </div>
    <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
      <div>
        <h2 class="text-xl">Текущий файл sitemap</h2>

        <div v-if="upd" class="p-4 my-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
          Файл sitemap был обновлен {{upd}}.
        </div>
        
        <pre class="max-w-full overflow-x-auto mt-4" id="current_sitemap" ref="current_sitemap"></pre>
        
      </div>
      <div>
        <h2 class="text-xl">Новый файл sitemap</h2>
        <div class="my-4">
          <button @click="generateSitemap()" type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Сгенерировать</button>
          <button @click="generateAndSave()" type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Сгенерировать и сохранить</button>
        </div>
        <!-- /.my-2 5 -->

        <LoadingComponent v-if="generateLoading"></LoadingComponent>

        <pre class="max-w-full overflow-x-auto mt-4" id="new_sitemap" ref="new_sitemap"></pre>
      </div>
    </div>
    <!-- /.mt-4 grid -->
  </div>
</template>

<script>
import 'highlight.js/scss/github-dark.scss'
import hljs from 'highlight.js';
import LoadingComponent from '@/components/LoadingComponent'
export default {

  name: 'SiteSiteMap',

  components: {
    LoadingComponent
  },

  data() {
    return {
      upd: null,
      generateLoading: false
    };
  },

  mounted(){
    this.getCurrentSitemap()
  },

  methods: {
    getCurrentSitemap() {
      this.$refs.current_sitemap.innerHTML = ''
      axios.get('sitemap')
            .then(data=>{

              this.upd = data.headers['x-upd']

              console.log(data);
              let highlightedCode = hljs.highlight(
                data.data,
                { language: 'xml' }
              ).value

              this.$refs.current_sitemap.innerHTML = highlightedCode
            })
            .catch(err=>{

            })
            .finally(()=>{

            })  
    },

    generateSitemap(){
      this.generateLoading = true
      this.$refs.new_sitemap.innerHTML = ''
      axios.get('sitemap/generate')
            .then(data=>{
              let highlightedCodeNew = hljs.highlight(
                data.data,
                { language: 'xml' }
              ).value

              this.$refs.new_sitemap.innerHTML = highlightedCodeNew
            })
            .catch(err=>{

            })
            .finally(()=>{
              this.generateLoading = false
            })  
    },

    generateAndSave(){
      this.generateLoading = true
      this.$refs.new_sitemap.innerHTML = ''
      axios.get('sitemap/update')
            .then(data=>{

              this.getCurrentSitemap()
            })
            .catch(err=>{

            })
            .finally(()=>{
              this.generateLoading = false
            })  
    }
  },
};
</script>

<style lang="css" scoped>
</style>
