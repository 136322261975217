<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Форма обратной связи</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="mt-4">
      <ErrorComponent v-if="error"/>
      <LoadingComponent v-if="loading"/>

      <div v-if="feedbacks && feedbacks.length == 0" class="flex p-4 my-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
        <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
        <span class="sr-only">Info</span>
        <div>
          <span class="font-medium">Упс..</span> Пока нет ни одного обращения
        </div>
      </div>

      <div class="relative overflow-x-auto" v-if="feedbacks && feedbacks.length > 0">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                Дата обращения
              </th>
              <th scope="col" class="px-6 py-3">
                Имя
              </th>
              <th scope="col" class="px-6 py-3">
                Телефон
              </th>
              <th scope="col" class="px-6 py-3" v-if="$store.getters.isAdmin"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="feedback in feedbacks">
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {{moment(feedback.created_at).fromNow()}}
              </th>
              <td class="px-6 py-4">
                {{feedback.name}}
              </td>
              <td class="px-6 py-4">
                <a :href="'tel:'+feedback.tel.replaceAll(/(\(|\)|\-)/g, '')">{{feedback.tel}}</a>
              </td>
              <td class="px-6 py-4" v-if="$store.getters.isAdmin">
                <button id="dropdownMenuIconButton" :data-dropdown-toggle="'dropdownDots'+feedback.id" class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button"> 
                  <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path></svg>
                </button>

                <!-- Dropdown menu -->
                <div :id="'dropdownDots'+feedback.id" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                    <ul class="py-2 text-sm text-red-700 dark:text-red-700" aria-labelledby="dropdownMenuIconButton">
                      <li>
                        <a @click.prevent="removeFeedback(feedback.id)" href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-red-600">Удалить</a>
                      </li>
                    </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex flex-col items-center mt-4" v-if="feedbacks && feedbacks.length > 0">
        <!-- Help text -->
        <span class="text-sm text-gray-700 dark:text-gray-400">
            Показана <span class="font-semibold text-gray-900 dark:text-white">{{currentPage}}</span> стр. из <span class="font-semibold text-gray-900 dark:text-white">{{lastPage}}</span>
        </span>
        <div class="inline-flex mt-2 xs:mt-0">
          <!-- Buttons -->
          <button class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" @click="prevPage()">
              <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
              Назад
          </button>
          <button class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 border-0 border-l border-gray-700 rounded-r hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" @click="nextPage()">
              Далее
              <svg aria-hidden="true" class="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </div>
      </div>

    </div>

  </div>
  
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent'
import ErrorComponent from '@/components/ErrorComponent'
import moment from 'moment';
import {initDropdowns} from 'flowbite'
export default {

  name: 'ServiceFeedbackShow',

  components: {
    LoadingComponent,
    ErrorComponent
  },

  data() {
    return {
      loading: true,
      error: false,
      currentPage: 1,
      lastPage: 1,
      feedbacks: null
    };
  },

  mounted(){
    this.getFeedback()
  },

  updated(){
    initDropdowns()
  },

  methods: {
    moment(e) {
      moment.locale('ru');
      return moment(e);
    },
    getFeedback(){
      this.error = false
      this.loading = true
      this.feedbacks = null
      axios.get('service/feedback'+((this.currentPage == 1) ? '' : '?page='+this.currentPage))
            .then(data=>{
              this.feedbacks = data.data.data.data
              this.currentPage = data.data.data.current_page
              this.lastPage = data.data.data.last_page
            })
            .catch(error=>{
              this.error = true
              console.log(error)
            })
            .finally(()=>{
              this.loading = false
            })
    },

    removeFeedback(id){
      console.log(id)
    },

    nextPage(){
      let next = this.currentPage;
      next++;

      if (next <= this.lastPage) {
        this.currentPage++;
        this.getCallback()
      }
    },

    prevPage(){
      let prev = this.currentPage;
      prev--;

      if (prev > 0) {
        this.currentPage--;
        this.getCallback()
      }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
