<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">API</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <iframe class="w-full h-full min-h-screen" src="https://cdn.prime-spb.com/doc/restapi/index.html">
      
    </iframe>
  </div>
</template>

<script>
export default {

  name: 'APIDoc',

  data() {
    return {

    };
  },
};
</script>

<style lang="css" scoped>
</style>
