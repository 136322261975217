<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Обновления</h1>

    <div class="mt-4">
      
      <div class="relative flex flex-col bg-gray-800 rounded md:rounded-xl p-4 mb-4">
        <div class="absolute inline-flex items-center justify-center px-2.5 h-6 text-xs font-bold text-white bg-purple-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">Новое</div>
        <div class="flex flex-row justify-start ">
          <h2 class="text-xl text-gray-400">Обновление 2.8.44</h2>
          
          <!-- /.text-2xl -->
        </div>
        <!-- /.flex flex-row -->

        <div class="flex">
          
        </div>
        <!-- /.flex -->

        <div class="flex">
          <p class="text-gray-500 italic">
            Обновление выпустил Имя 28ю07ю2023
          </p>
        </div>
        <!-- /.flex -->


      </div>
      <!-- /.flex flex-col -->

    </div>
    <!-- /.mt-4 -->

    <div class="mt-4">
      <h3 class="text-lg">
        Прошлые обновления
      </h3>
      <!-- /.text-md -->
    </div>
    <!-- /.mt-4 -->
  </div>
  <LockComponent></LockComponent>
</template>

<script>
import LockComponent from '@/components/LockComponent'
export default {

  name: 'Updates',

  components: {
    LockComponent
  },

  data() {
    return {

    };
  },
};
</script>

<style lang="css" scoped>
</style>
