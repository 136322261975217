<template>
  <div class="container mt-3">
    <div class="row">
      <div class="col-md-12 col-12">
        <div class="alert alert-danger" role="alert">
          <h4 class="alert-heading">Ошибка #404</h4>
          <p>Запрошенная страница не найдена. Возможно, она еще не создана.</p>
          <hr>
          <p class="mb-0">Если Вы гарантированно уверены, что перешли точно туда, куда надо, то сообщите об ошибке.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'Error404',

  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
</style>
