<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Создать категорию</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="mt-4">
      <ErrorComponent v-if="error" :errors="errors"/>
      <LoadingComponent v-if="loading"/>


      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>

          <div class="mb-4">
            <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Название</label>
            <input type="text" id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Ямочный ремонт" required v-model="newCategory.title">
          </div>
          <!-- /.mb-4 -->

          <div class="mb-4">
            
            <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Описание</label>
            <textarea id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="newCategory.description"></textarea>

          </div>
          <!-- /.mb-4 -->


          <div class="mb-4">
            
            <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Meta-описание</label>
            <textarea id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="newCategory.meta_description"></textarea>

          </div>
          <!-- /.mb-4 -->

        </div>
        <div>

          <div class="mb-4">
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="formFile">Изображение обложки</label>
            <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="formFile" type="file" accept="image/*" @change="setImage">

            <div class="mb-4">
              <vue-cropper
                class="mt-4"
                v-if="imageToCrop"
                ref="cropper"
                :aspect-ratio="1 / 1"
                :src="imageToCrop"
                @crop="cropImage"
              />
            </div>
            <!-- /.mb-4 -->
          </div>
          <!-- /.mb-4 -->
          
        </div>
      </div>
      <!-- /.grid -->

      <div class="my-3">
        <label class="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" value="" class="sr-only peer" v-model="newCategory.is_published" :true-value="1" :false-value="0">
          <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Опубликовать после сохранения</span>
        </label>
      </div>

      <div class="my-3">
        <button @click="save()" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Сохранить</button>
      </div>

    </div>
  </div>
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent'
import ErrorComponent from '@/components/ErrorComponent'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
export default {

  name: 'SiteCategoryCreate',

  components: {
    VueCropper,
    LoadingComponent,
    ErrorComponent
  },

  data() {
    return {
      error: false,
      errors: [],
      newCategory: {
        is_published: 0
      },

      fd: new FormData(),

      imageToCrop: null
    };
  },

  methods: {
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        new Noty({
          type: 'error',
          text: 'Пожалуйста, выбирете ИЗОБРАЖЕНИЕ.',
          timeout: 1500
        }).show()
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (this.imageToCrop) {
            this.$refs.cropper.replace(event.target.result);
          }
          this.imageToCrop = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        new Noty({
          type: 'error',
          text: 'К сожалению, FileReader API не поддерживается браузером.',
          timeout: 1500
        }).show()
      }
    },

    cropImage() {
      this.$refs.cropper.getCroppedCanvas({width: 500, height: 500})
                        .toBlob(blob => {
                          this.fd.append("img", blob, 'image.png');
                        });
      console.info('Изображение на загрузку обновлено.');
    },

    save(){
      for (const [key, value] of Object.entries(this.newCategory)) {
        this.fd.append(key, value);
      }
      axios
          .post('category', this.fd)
          .then(data=>{
            this.$router.push({name: 'category.show', params: {id: data.data.data.id}})
          })
          .catch(err=>{
            this.error = true
            this.errors = err?.response.data.errors
            console.log(err)
          })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
