export function HandleError(err, customPretext = '') {
  
  let messages = err?.response?.data?.errors || null;

  console.log(err)
  console.log(messages)

  if (messages) {
    messages.forEach(function(entry) {
      new Noty({
        type: 'error',
        text: customPretext + entry
      }).show();
    });
  }else{
    new Noty({
      type: 'error',
      text: customPretext + 'Возникла неизвестная ошибка. Повторите запрос позже.'
    }).show();
  }
}