<template>
  <div class="container mx-auto p-4 h-full">
    <div class="flex flex-row justify-between items-center">
      <h1 class="text-3xl text-black dark:text-white">О приложении</h1>

      <label class="relative inline-flex items-center mr-5 cursor-pointer">
        <input type="checkbox" value="" class="sr-only peer" @change="changeDebug" :checked="$store.getters.debug">
        <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-yellow-300 dark:peer-focus:ring-yellow-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-400"></div>
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">DebugMode</span>
      </label>
    </div>
    

    <div class="w-full h-full flex flex-col items-center align-middle justify-center mt-6 md:mt-24">
      <img src="@/assets/logo_prime_spb.svg" class="max-w-[250px]" alt="">
      <span class="mt-3 text-2xl">{{$app_name}}</span>
      <span class="text-gray-600">v {{$app_v}}</span>
      <span class="text-gray-600">{{$app_date}}</span>
    </div>
  </div>
</template>

<script>
import {HandleError} from '@/helpers/errorHandler.js'
export default {

  name: 'ServiceAbout',

  data() {
    return {
      debug: this.$store.getters.debug
    };
  },

  methods: {
    changeDebug(e) {
      let sta = (e.target.checked) ? true : false;

      axios
          .post('service/setDebugMode', {
            debug: sta
          })
          .then(data=>{
            this.$store.dispatch('debug', sta)
          })
          .catch(err=>{
            HandleError(err)
            e.target.checked = !sta
          })
    },
  },
};
</script>

<style lang="css" scoped>
</style>
