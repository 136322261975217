<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Почтовая рассылка</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-2">
      <div>
        <div class="rounded md:rounded-xl bg-gray-700 p-4">
          <div class="text-xl">КП для ТСЖ, ЖСК, УК</div>
          <!-- /.text-xl -->
          <div class="text-gray-400">
            Коммерческое предложение для ТСЖ, ЖСК, УК
          </div>

          <div id="accordion-flush" data-accordion="collapse" data-active-classes="text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
            <h2 id="accordion-flush-heading-1">
              <button type="button" class="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" data-accordion-target="#accordion-flush-body-1" aria-expanded="false" aria-controls="accordion-flush-body-1">
                <span>Просмотр</span>
                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                </svg>
              </button>
            </h2>
            <div id="accordion-flush-body-1" class="hidden" aria-labelledby="accordion-flush-heading-1">
              <div class="py-2.5 border-b border-gray-200 dark:border-gray-700">
                <img class="mx-auto" src="@/assets/mail1.png" alt="">
              </div>
            </div>
          </div>

          <div class="mt-4">
            
            <form class="flex items-center">   
              <input type="text" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 md:mr-2.5" placeholder="a@prime-spb.com" required v-model="send.email">
              <input type="text" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 md:mr-2.5" placeholder="Иванов Иван Иванович" required v-model="send.name">
              <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-auto sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" @click="sendMessage('uktszh')">Отправить</button>
            </form>

          </div>
        </div>
      </div>


      <div>
        <div class="rounded md:rounded-xl bg-gray-700 p-4">
          <div class="text-xl">КП для БЦ, СП</div>
          <!-- /.text-xl -->
          <div class="text-gray-400">
            Коммерческое предложение для складских, производственных площадок, бизнесс центров
          </div>

          <div id="accordion-flush-1" data-accordion="collapse" data-active-classes="text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
            <h2 id="accordion-flush-heading-1">
              <button type="button" class="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" data-accordion-target="#accordion-flush-body-2" aria-expanded="false" aria-controls="accordion-flush-body-2">
                <span>Просмотр</span>
                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                </svg>
              </button>
            </h2>
            <div id="accordion-flush-body-2" class="hidden" aria-labelledby="accordion-flush-heading-1">
              <div class="py-2.5 border-b border-gray-200 dark:border-gray-700">
                <img class="mx-auto" src="@/assets/mail2.png" alt="">
              </div>
            </div>
          </div>

          <div class="mt-4">
            
            <form class="flex items-center">   
              <input type="text" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 md:mr-2.5" placeholder="a@prime-spb.com" required v-model="send.email">
              <input type="text" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 md:mr-2.5" placeholder="Иванов Иван Иванович" required v-model="send.name">
              <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-auto sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" @click="sendMessage('sppz')">Отправить</button>
            </form>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {initAccordions} from 'flowbite';
export default {

  name: 'MailingList',

  data() {
    return {
      send: {
        email: null,
        type: null,
        name: null
      }
    };
  },

  mounted(){
    initAccordions()
  },


  methods: {
    sendMessage(type) {
      this.send.type = type

      axios
          .post('emails/send', this.send)
          .then(data=>{
            this.send = {
              email: null,
              type: null,
              name: null
            }

            new Noty({
              type: 'success',
              text: 'Сообщение отправлено.'
            }).show();
          })
          .catch(err=>{
            console.log(err)
            new Noty({
              type: 'error',
              text: 'Невозможно отправить сообщение на этот адрес.'
            }).show();
          })
    },
  },
};
</script>

<style lang="css" scoped>
</style>
