<template>
  <div class="container mx-auto p-4">
    <span class="block py-3"><router-link :to="{name: 'service.users'}" class="text-sky-600 text-md">&lt; К списку пользователей</router-link></span>
    <h1 class="text-3xl text-black dark:text-white">Создать пользователя</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="mt-4">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <form @submit.prevent="createUser()">
            <fieldset>
              <div class="mb-4">
                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ФИО</label>
                <input type="text" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Иванов Иван Иванович" required v-model="newUser.name">
              </div>
              <div class="mb-4">
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">e-mail</label>
                <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="a@kteb.pl" required v-model="newUser.email">
              </div>
              <div class="mb-4">
                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Пароль</label>
                <input type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="•••••••••" required v-model="newUser.password">
              </div> 
              <div class="mb-4">
                <label class="relative inline-flex items-center mr-5 cursor-pointer">
                  <input type="checkbox" value="" class="sr-only peer" v-model="newUser.isAdmin" :true-value="1" :false-value="0">
                  <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                  <span class="flex align-baseline items-center ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Наделить супер-правами <button data-popover-target="popover-superuser" data-popover-placement="right" type="button"><svg class="w-4 h-4 ml-2 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg><span class="sr-only">Show information</span></button>
                  </span>
                  <div data-popover id="popover-superuser" role="tooltip" class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                    <div class="p-3 space-y-2">
                      <h3 class="font-semibold text-gray-900 dark:text-white">Супер-пользователь</h3>
                      <p>Суперпользователю доступен весь функционал админ-панели, включая сервисные функции, которые могут навредить работе системы.</p>
                      <p class="mt-3">Этот флажок необходимо указывать только в крайних случаях.</p>
                    </div>
                    <div data-popper-arrow></div>
                  </div>
                </label>
              </div>
              <!-- /.mb-4 -->
              <div class="mb-4">
                <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Создать</button>
              </div>
              <!-- /.mb-4 -->
            </fieldset> 
          </form>
        </div>
        <div>
          <ErrorComponent v-if="errors" :errors="errors"/>
          <div class="flex p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
            <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <div>
              <span class="font-medium text-lg">Информация о пользователях</span>
              <p class="my-2.5">
                Пользователь - это человек, имеющий доступ к панели администратора системы Prime.
              </p>
              <p class="my-2.5">
                Данная система позволяет управлять проектом prime, а равно пользователь может создавать/удалять/изменять записи и содержание сайта.
              </p>
              <p class="my-2.5">
                У пользователя должен быть <b>надёжный</b> пароль, который он обязуется никому не передавать.
              </p>
              <p class="my-2.5">
                Пользователя может создать исключительно администратор системы.
              </p>
              <p class="my-2.5">
                Из соображений безопасности НЕ РЕКОМЕНДУЕТСЯ создавать более 2 администраторов. Функционал обычного пользователя удовлетворяет всем потребностям для работы с ИС, а так же запрещает действия, которые несут вред данным и работе системы.
              </p>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
import ErrorComponent from '@/components/ErrorComponent'
import {initPopovers} from 'flowbite'
export default {

  name: 'ServiceUserCreate',
  components: {
    ErrorComponent
  },
  mounted(){
    initPopovers()
  },

  data() {
    return {
      errors: null,
      newUser: {

      }
    };
  },

  methods: {
    createUser() {
      this.errors = null;
      this.$swal.fire({
        title: 'Нам точно нужен новый пользователь?',
        showCancelButton: true,
        cancelButtonText: 'Нет, я случайно',
        confirmButtonText: 'Да',
      }).then((result) => {
        if (result.isConfirmed) {
          
          if(this.newUser.isAdmin){

            this.$swal.fire({
              title: 'Вы пытаетесть создать пользователя с максимальными правами.',
              text: 'Такой пользователь имеет полный доступ к системе. \r\n\r\nМожет, всё-таки передумаем?',
              showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#6e7881',
              confirmButtonText: 'Да, я хочу создать пользователя.',
              cancelButtonText: 'Я передумал'
            }).then((res) => {
              if (res.isConfirmed) {
                axios
                    .post('user/create', this.newUser)
                    .then(data=>{
                      this.$router.push({name: 'service.users'})
                    })
                    .catch(err=>{
                      console.log(err)
                      this.errors = err?.response.data.errors;
                      new Noty({
                        type: 'error',
                        text: 'Не удалось создать пользователя'
                      }).show()
                    })
                    .finally()
              }
            })
          }else{
            axios
                .post('user/create', this.newUser)
                .then(data=>{
                  this.$router.push({name: 'service.users'})
                })
                .catch(err=>{
                  console.log(err)

                  this.errors = err?.response.data.errors;

                  new Noty({
                    type: 'error',
                    text: 'Не удалось создать пользователя'
                  }).show()
                })
                .finally()
          }
        }
      })
    },
  },
};
</script>

<style lang="css" scoped>
</style>
