<template>
  <div class="flex flex-col grow bg-gray-800 rounded md:rounded-xl p-4 mr-0 min-h-96">
    <div class="flex flex-row justify-between items-center">
      <h2 class="text-xl mb-2.5">Статистика посетителей</h2>
      <a href="#" @click.prevent="getStatData()">
        <svg :class="{'animate-spin': loading}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
      </a>
    </div>

    <div class="flex flex-row my-2.5 overflow-x-auto overflow-y-hidden">
      <TodayClicksComponent></TodayClicksComponent>
      <TodayAdClicksComponent></TodayAdClicksComponent>
    </div>
    <!-- /.flex flex-row my-2 5 -->

    <div class="flex flex-row justify-around my-2.5">

      <div class="flex items-center mb-4">
        <input id="default-radio-1" type="radio" value="5" name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" v-model="days">
        <label for="default-radio-1" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">5 дней</label>
      </div>

      <div class="flex items-center mb-4">
        <input id="default-radio-2" type="radio" value="7" name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" v-model="days">
        <label for="default-radio-2" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">7 дней</label>
      </div>

      <div class="flex items-center mb-4">
        <input id="default-radio-3" type="radio" value="14" name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" v-model="days">
        <label for="default-radio-3" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">14 дней</label>
      </div>

      <div class="flex items-center mb-4">
        <input id="default-radio-4" type="radio" value="28" name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" v-model="days">
        <label for="default-radio-4" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">28 дней</label>
      </div>

    </div>
    <!-- /.flex flex-row -->
    <div id="stat-chart"></div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts'
import TodayClicksComponent from '@/components/dash/TodayClicksComponent'
import TodayAdClicksComponent from '@/components/dash/TodayAdClicksComponent'
export default {

  name: 'DashGraphComponent',

  components: {
    TodayClicksComponent,
    TodayAdClicksComponent
  },

  data() {
    return {
      days: 5,
      loading: true,
      data: null,
      chart: null
    };
  },

  mounted(){
    this.createStatChart()
    this.getStatData()
  },

  unmounted(){
    this.chart.destroy()
  },


  computed: {
    graphSeries() {
      let series = [];

      for (const [key, value] of Object.entries(this.data.data)) {
        series.push(this.data.data[key].count)
      }

      series = series.reverse()

      return series;
    },

    graphCategories() {
      let categories = [];

      for (const [key, value] of Object.entries(this.data.data)) {
        categories.push(key)
      }

      categories = categories.reverse()

      return categories;
    },
  },



  methods: {

    getStatData(){
      this.loading = true

      this.chart.updateSeries([])

      this.chart.updateOptions({
        xaxis: {
          categories: []
        }
      })

      axios
          .get('stat?count='+this.days)
          .then(data=>{
            this.data = data.data.data
            this.updateChart()
          })
          .catch(err => {

          })
          .finally(()=>{
            this.loading = false
          })
    },

    

    updateChart(){

      this.chart.updateSeries([{
        name: 'Посетители',
        data: this.graphSeries
      }])

      this.chart.updateOptions({
        xaxis: {
          categories: this.graphCategories
        }
      })
    },

    

    createStatChart(){

      var options = {
        tooltip: {
          theme: 'dark'
        },
        chart: {
          id: 'stat-chart',
          type: 'line',
          foreColor: '#ccc',
          toolbar: {
            show: false
          },
        },
        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 3,
        },
        // series: [{
        //   name: 'sales',
        //   data: [30,40,35,50,49,60,70,91,125]
        // }],
        series: [],
        xaxis: {},
        grid: {
          borderColor: "#535A6C",
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        noData: {
          text: 'Loading...'
        }
      }

      this.chart = new ApexCharts(document.querySelector("#stat-chart"), options);

      this.chart.render();

    },

  },

  watch: {
    'days': {
      handler: function(newValue) {
        this.getStatData()
      },
      deep: true
    }
  }
};
</script>

<style lang="css" scoped>
</style>
