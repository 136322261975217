import { createStore } from 'vuex'

let token = localStorage.getItem('prime_token');

export default createStore({
  state: {
    siteBaseURL: 'https://prime-spb.com',
    login: !!token,
    exp: null,
    backURL: null,
    isAdmin: !!token,
    debug: false
  },
  getters: {
    isLoggedIn: state => state.login,
    isAdmin: state => state.isAdmin,
    backUrl: state => state.backURL,
    debug: state => state.debug
  },
  mutations: {
    setToken(state, access_token){
      state.access_token = access_token
    },

    setExp(state, exp){
      state.exp = exp
    },

    setBackUrl(state, url){
      state.backURL = url
    },

    setLoginState(state, val){
      state.login = val
    },

    setAdminState(state, val){
      state.isAdmin = val
    },
    setDebugState(state, val){
      state.debug = val
    }
  },
  actions: {
    login(context, token){
      context.commit('setAdminState', true)
      context.commit('setLoginState', true)

      var date = new Date();

      date.setSeconds(date.getSeconds() + 3600);

      context.commit('setExp', date)
      localStorage.setItem('prime_token', token.token);
    },
    logout(context){
      context.commit('setLoginState', false)
      context.commit('setExp', null)
      localStorage.removeItem('prime_token');
    },
    debug(context, state){
      console.log(state)
      context.commit('setDebugState', state)
    }
  },
  modules: {
  }
})
