<template>
  <div class="flex flex-col rounded md:rounded-xl p-0 mx-2 dark:bg-gray-800 hover:dark:bg-gray-700">
    <div>
      <img class="rounded-t md:rounded-t-lg" :src="'//prime-spb.com/uploads/portfolio_thumb/'+post.thumb_img" alt="">
    </div>
    <div class="p-2 md:px-4">
      <router-link :to="{name: 'post.show', params: {id: post.id}}" class="text-xl dark:text-white">{{post.title}}</router-link>
      <!-- /.text-xl -->
      <p class="text-sm dark:text-gray-500 mt-2.5">
        {{post.description}}
      </p>
    </div>
  </div>
</template>

<script>
export default {

  name: 'PostItemComponent',

  props: {
    post: {
      required: true,
      type: Object
    }
  },

  data() {
    return {

    };
  },
};
</script>

<style lang="css" scoped>
</style>
