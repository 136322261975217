<template>
  <section class="bg-gray-50 dark:bg-gray-900 h-full">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-full md:h-screen lg:py-0">
      <router-link :to="{name: 'index'}" class="flex flex-col items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white -mt-2 md:-mt-48 no-underline">
          <img class="h-16" src="@/assets/logo_prime_spb.svg" alt="logo">
          <span class="italic text-[#003a7f]">Admin</span>
      </router-link>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Вход в систему
          </h1>
          <div v-if="$route.query.drop" class="flex items-center p-4 my-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
            <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span class="sr-only">Info</span>
            <div>
              Ваша сессия завершена
            </div>
          </div>

          <form class="space-y-4 md:space-y-6" action="#" @submit.prevent="login">
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
              <input 
               type="email" 
               name="email" 
               id="email" 
               class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
               placeholder="login@prime-spb.com"
               v-model="user.email">
            </div>
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Пароль</label>
              <input 
               type="password" 
               name="password" 
               id="password" 
               placeholder="••••••••" 
               class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
               v-model="user.password">
            </div>
            <button type="submit" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Войти</button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

  name: 'Login',

  data() {
    return {
      user: {
        email: '',
        password: ''
      }
    };
  },

  mounted(){
    if (this.$store.getters.isLoggedIn) {
      this.$router.push({name: 'index'});
    }

    if (this.$store.getters.backUrl){
      console.info(this.$store.getters.backUrl)
    }

  },

  methods: {
    login(){
      this.$progress.start();

      axios.post('user/login', this.user)
      .then(data=>{
        console.log(data)
        if (data.data.access_token) {
          this.$store.dispatch('login', {token: data.data.access_token})
          if (this.$store.getters.backUrl){
            const bck = this.$store.getters.backUrl;
            this.$store.commit('setBackUrl', null);
            this.$router.push(bck)
          }else{
            this.$router.push({name: 'index'})
          }
        }
      })
      .catch(error => {
        console.log(error);
        this.$swal.fire({
          text: 'Не удалось войти в систему. Проверьте правильность логина и пароля и повторите попытку.',
          imageUrl: require('@/assets/tb.png'),
          imageWidth: '300px'
        })
      })
      .finally(()=>{
        this.$progress.finish();
      })
    }
  }
};
</script>

<style lang="css" scoped>
</style>
