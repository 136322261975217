<template>
  <div class="bg-gray-700 p-2.5 rounded md:rounded-xl w-1/2 md:w-1/3">
    <span>Кликов сегодня</span>
    <div id="today_click"></div>
    <!-- /#today_click -->
  </div>
  <!-- /.bg-gray-700 p-2 5 rounded md:rounded-xl -->
</template>

<script>
import ApexCharts from 'apexcharts'
export default {

  name: 'TodayClicksComponent',

  data() {
    return {
      todayClickChart: null,
      todayClicksData: null
    };
  },

  mounted(){
    this.createTodayClickChart()
    this.getClicks()
  },

  unmounted(){
    this.todayClickChart.destroy()
  },

  computed: {


    clicksSeries(){
      let series = [];

      for (const [key, value] of Object.entries(this.todayClicksData)) {
        series.push(this.todayClicksData[key].count)
      }

      //series = series.reverse()

      return series;
    },

    clickCategories() {
      let categories = [];

      for (const [key, value] of Object.entries(this.todayClicksData)) {
        categories.push(key)
      }

      //categories = categories.reverse()

      return categories;
    },
  },

  methods: {
    getClicks(){
      axios.get('stat/clicksToday')
            .then(data=>{
              this.todayClicksData = data.data.data
              this.updateClickChart()
            })
    },
    updateClickChart(){

      this.todayClickChart.updateSeries([{
        name: 'Посетители',
        data: this.clicksSeries
      }])

      // this.todayClickChart.updateOptions({
      //   xaxis: {
      //     categories: this.clickCategories
      //   }
      // })
    },
    createTodayClickChart(){
      var options = {
        chart: {
          id: 'spark1',
          group: 'sparks',
          type: 'line',
          height: 80,
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 2,
            opacity: 0.2,
          }
        },
        series: [{
          data: [25, 66, 41, 59, 25, 44, 12, 36, 9, 21]
        }],
        stroke: {
          curve: 'smooth'
        },
        markers: {
          size: 0
        },
        grid: {
          padding: {
            top: 20,
            bottom: 10,
          }
        },
        colors: ['#fff'],
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function formatter(val) {
                return '';
              }
            }
          }
        }
      }

      this.todayClickChart = new ApexCharts(document.querySelector("#today_click"), options);
      this.todayClickChart.render();

    }
  }
};
</script>

<style lang="css" scoped>
</style>
