<template>
  <div v-if="callback || feedback" class="flex flex-row justify-between mb-4 gap-4">
    
    <router-link v-if="callback" :to="{name: 'service.callbacks'}" class="relative flex flex-col p-2 md:p-4 rounded md:rounded-xl bg-gray-800 w-1/2">
      <span class="text-xl">Новые заявки</span>
      <p class="text-gray-500">
        Имеются новые заявки на обратную связь
      </p>
      <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">{{callback}}</div>
    </router-link>

    <a v-if="feedback" href="#" class="relative flex flex-col p-2 md:p-4 rounded md:rounded-xl bg-gray-800 w-1/2">
      <span class="text-xl">Новые заявки</span>
      <p class="text-gray-500">
        Зарегистрированы новые обращения
      </p>
      <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">{{feedback}}</div>
    </a>

  </div>
  <!-- /.flex flex-row -->
</template>

<script>
import {HandleError} from '@/helpers/errorHandler.js'
export default {

  name: 'NoticeComponent',

  data() {
    return {
      callback: null
    };
  },

  mounted(){
    this.getCallback()
  },

  methods: {
    getCallback() {
      axios.get('service/callback/new')
            .then(data=>{
              this.callback = data.data.data.count
            })
            .catch(err => {
              HandleError(err, 'Ошибка получения новых заявок: ')
            })
    },
  },
};
</script>

<style lang="css" scoped>
</style>
