<template>
  <div class="container mx-auto p-4">
    <span class="block py-3"><router-link :to="{name: 'service.callbacks'}" class="text-sky-600 text-md">&lt; К списку заявок</router-link></span>

    <h1 class="text-3xl text-black dark:text-white">Просмотр заявки</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="mt-4">
      <ErrorComponent v-if="error"/>
      <LoadingComponent v-if="loading"/>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4" v-if="callback && !loading && !error">
        <div>
          <div class="flex flex-col mb-4">
            <span class="text-blue-600">Имя</span>
            <span>{{callback.name}}</span>
          </div>
          <div class="flex flex-col mb-4">
            <span class="text-blue-600">Телефон</span>
            <span><a :href="'tel:'+callback.tel.replace(/\(|\)|\-/gi, '')">{{callback.tel}}</a></span>
          </div>
        </div>
        <div>
          <div class="flex flex-col mb-4">
            <span class="text-blue-600">Хеш события</span>
            <span>{{callback.hash}}</span>
          </div>
          <div class="flex flex-col mb-4">
            <span class="text-blue-600">ВИД</span>
            <span>{{callback.id}}</span>
          </div>
          <div class="flex flex-col mb-4">
            <span class="text-blue-600">Создано</span>
            <span class="cursor-pointer" data-tooltip-target="tooltip-created-at">{{moment(callback.created_at).fromNow()}}</span>
            <div id="tooltip-created-at" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
              {{moment(callback.created_at).format("DD.MM.YYYY HH:mm:ss")}}
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
          </div>
          <div class="flex flex-col mb-4">
            <span class="text-blue-600">Обновлено</span>
            <span class="cursor-pointer" data-tooltip-target="tooltip-updated-at">{{moment(callback.updated_at).fromNow()}}</span>
            <div id="tooltip-updated-at" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
              {{moment(callback.updated_at).format("DD.MM.YYYY HH:mm:ss")}}
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.grid -->
    </div>

     
  </div>
  <!-- /.container mx-auto p-4 -->
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent'
import ErrorComponent from '@/components/ErrorComponent'
import {initDropdowns, initTooltips} from 'flowbite'
import moment from 'moment';
export default {

  name: 'CallbackView',
  props: ['hash'],
  components:{
    LoadingComponent,
    ErrorComponent
  },
  data() {
    return {
      loading: false,
      error: false,
      callback: null
    };
  },
  mounted(){
    initDropdowns()
    initTooltips()
    this.getCallback()
  },

  updated(){
    initDropdowns()
    initTooltips()
  },

  methods: {
    getCallback(){
      this.error = false
      this.loading = true
      axios
          .get('service/callback/'+this.hash)
          .then(data=>{
            this.callback = data.data.data
          })
          .catch(err=>{
            this.error = true
            console.log(err)
          })
          .finally(()=>{this.loading = false})
    },
    moment(e){
      moment.locale('ru');
      return moment(e);
    }
  }
};
</script>

<style lang="css" scoped>
</style>
