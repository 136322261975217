<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Создать публикацию</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="my-2 flex flex-row align-baseline">
      <button @click="savePost()" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Сохранить</button>

      <div class="flex items-center mb-2 ml-4">
        <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" :true-value="1" :false-value="0" v-model="post.is_published">
        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Опубликовать после сохранения</label>
      </div>

    </div>

    <ErrorComponent v-if="error" :errors="errors"></ErrorComponent>

    <div class="mt-4">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div class="mb-4">
            <label for="portfolio_category_id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Категория</label>
            <select id="portfolio_category_id" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="post.portfolio_category_id">
              <option v-if="categories" v-for="category in categories" :value="category.id">{{category.title}}</option>
            </select>
          </div>
          <!-- /.mb-4 -->
          <div class="mb-4">
            <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Заголовок публикации</label>
            <input type="text" id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Асфальтирование в ООО &laquo;Рога и копыта&raquo;" required v-model="post.title">
          </div>
          <div class="mb-4">
            <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Короткое описание</label>
            <textarea id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Выполнение работ по обустройству отмостки в УК &laquo;Мир&raquo;" v-model="post.description"></textarea>
          </div>

          <div class="mb-4">
            <label for="ceo_description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">meta - описание</label>
            <textarea id="ceo_description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="... описание для поисковиков ..." v-model="post.ceo_description"></textarea>
          </div>

          <div class="mb-4">
            <label for="html" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Форматированное описание</label>

            <ckeditor :editor="editor" v-model="post.html" :config="editorConfig"></ckeditor>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <div class="mb-4">
                <label for="dte" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Дата выполнения</label>
                <VueDatePicker
                  id="dte"
                  v-model="post.dte"
                  model-type="yyyy-MM-dd"
                  format="dd.MM.yyyy"
                  locale="ru"
                  :dark="true"
                  :enable-time-picker="false"
                />
              </div>
            </div> 
            <div>
              <div class="mb-4">
                <label for="vol" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Обьём работ (м<sup>2</sup>)</label>
                <input type="tel" id="vol" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="357" required v-model="post.vol">
              </div>
            </div>
            <div>
              <div class="mb-4">
                <label for="completion_time" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Срок выполнения (д.)</label>
                <input type="tel" id="completion_time" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="3" required v-model="post.completion_time">
              </div>
            </div>
          </div>
          <!-- /.grid -->

          <div class="mb-4">
            <label for="loc" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Адрес выполнения</label>
            <input type="text" id="loc" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Санкт-Петербург, ул.Мира, 84" required v-model="post.loc">
          </div>
          <!-- /.mb-4 -->

        </div>
        <div>
          <div class="mb-4">
            <label for="thumb_img" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Изображение обложки</label>
            <input @change="setImage" class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="thumb_img" type="file">

            <VueCropper
              class="mt-4"
              v-if="imageToCrop"
              ref="cropper"
              :src="imageToCrop"
              :aspect-ratio="1 / 1"
              @crop="cropImage()"
            />
          </div>
          <!-- /.mb-4 -->
        </div>
      </div>
      <!-- /.grid -->      
    </div>  
  </div>
  <!-- /.container mx-auto p-4 -->
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru';

import VueDatePicker from '@vuepic/vue-datepicker';


import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

import ErrorComponent from '@/components/ErrorComponent'
export default {

  name: 'SitePostCreate',

  components: {
    VueDatePicker,
    VueCropper,
    ckeditor: CKEditor.component,
    ErrorComponent
  },

  data() {
    return {
      error: false,
      errors: [],

      post: {

      },


      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList', '|', 'blockQuote', '|','undo', 'redo'],

        bold: {
          options:{
            classes: ['text-bold']
          }
        }
      },

      imageToCrop: null,

      fd: new FormData(),
      
      categories: null,
      loading: false,
      

      uploadReady: false,
      uploadId: null
    };
  },

  mounted(){
    this.getCategories()
  },

  methods: {
    getCategories(){
      this.categories = null;
      this.error = false;
      axios.get('category?withoutPagination=1')
      .then(data=>{
        if (data.data.ok) {
          this.categories = data.data.data;
        }
      })
      .catch(error=>{
        console.log(error)
        this.error = true;
        this.error_code = 'Ошибка при загрузке категорий'
      })
      .finally()
    },

    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        new Noty({
          type: 'error',
          text: 'Пожалуйста, выбирете ИЗОБРАЖЕНИЕ.',
          timeout: 1500
        }).show()
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (this.imageToCrop) {
            this.$refs.cropper.replace(event.target.result);
          }
          this.imageToCrop = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        new Noty({
          type: 'error',
          text: 'К сожалению, FileReader API не поддерживается браузером.',
          timeout: 1500
        }).show()
      }
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.$refs.cropper.getCroppedCanvas({width: 500, height: 500})
                        .toBlob(blob => {
                          this.fd.append("thumb_img", blob, 'image.png');
                        });
        
      console.info('Изображение на загрузку обновлено.');
    },

    savePost(){

      for (const [key, value] of Object.entries(this.post)) {
        this.fd.append(key, value);
      }

      axios
          .post('post', this.fd)
          .then(data=>{
            this.$router.push({name: 'post.show', params: {id: data.data.data.id}})
          })
          .catch(err=>{
            this.error = true
            this.errors = err?.response.data.errors
            console.log(err)
          })
      
    }
  }
};
</script>
<style lang="scss">
  .ck-editor__editable[role="textbox"] {
      /* editing area */
      min-height: 200px;
      color: #000;
  }
</style>