<template>
  <div class="relative flex flex-col bg-gray-800 rounded md:rounded-xl my-2 p-2 md:p-4 hover:bg-gray-700">
    <div class="flex flex-row justify-start">
      <div class="w-1/3">
        <img class="rounded md:rounded-xl" :src="'https://prime-spb.com/uploads/portfolio_categories/'+category.img" alt="">
      </div>
      <div class="w-2/3 ml-2.5">
        <router-link :to="{name: 'category.show', params: {id: category.id}}" class="text-2xl text-gray-900 dark:text-gray-300">{{category.title}}</router-link>
        <!-- /.text-2xl -->
        <p class="text-gray-700 dark:text-gray-400">
          {{category.description}}
        </p>
        <p class="text-gray-700 dark:text-gray-400 my-2.5">
          Постов в категории: {{category.posts.length}}
        </p>
      </div>
    </div>
    <!-- /.flex flex-row -->

    <div class="text-gray-400 text-sm mt-2.5">
      Категория опубликована <span>{{moment(category.created_at).fromNow()}}</span><span v-if="category.updated_at">, изменена <span>{{moment(category.updated_at).fromNow()}}</span></span>
    </div>

    <div v-if="!category.is_published" class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>

    <div v-if="category.is_published" class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-green-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
    </div>
    
  </div>
  <!-- /.flex flex-row -->
</template>

<script>
import moment from 'moment'
export default {

  name: 'CaregoryCardComponent',

  props:{
    category: {
      required: true,
      type: Object
    }
  },

  data() {
    return {

    };
  },

  methods: {
    moment(e) {
      moment.locale('ru')
      return moment(e)
    },
  },
};
</script>

<style lang="css" scoped>
</style>
