<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Контрагенты</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="mt-4">
      <ErrorComponent v-if="error"/>
      <LoadingComponent v-if="loading"/>

      <div v-if="contragents && contragents.length == 0" class="flex p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
        <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
        <span class="sr-only">Info</span>
        <div>
          <span class="font-medium">В настоящий момент нет ни одного контрагента</span>
        </div>
      </div>

      <div class="relative overflow-x-auto" v-if="contragents && contragents.length > 0">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    id
                  </th>
                  <th scope="col" class="px-6 py-3">
                    
                  </th>
                  <th scope="col" class="px-6 py-3">
                      Category
                  </th>
                  <th scope="col" class="px-6 py-3">
                      Price
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          Apple MacBook Pro 17"
                      </th>
                      <td class="px-6 py-4">
                          Silver
                      </td>
                      <td class="px-6 py-4">
                          Laptop
                      </td>
                      <td class="px-6 py-4">
                          $2999
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>

    </div>
  </div>
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent'
import ErrorComponent from '@/components/ErrorComponent'
import Inputmask from "inputmask";
export default {
  name: 'CrmContragents',

  components: {
    LoadingComponent,
    ErrorComponent
  },

  data() {
    return {
      loading: false,
      error: false,
      errors: [],
      contragents: null,
      currentPage: 1,
      lastPage: 1
    };
  },

  mounted(){
    this.getContragents()
  },

  methods: {
    getContragents(){
      this.loading = true;
      this.error = false
      this.errors = []
      axios.get('crm/contragent')
            .then(data => {
              if (data.data.ok) {
                this.contragents = data.data.data.data
                this.currentPage = data.data.data.current_page
                this.lastPage = data.data.data.last_page
              }
            })
            .catch(err=>{
              console.log(err)
              this.error = true
            })
            .finally(()=>{
              this.loading = false;
            })
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
