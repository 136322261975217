<template>
  <div class="container mx-auto p-4">
    <span class="block py-3"><router-link :to="{name: 'post.all'}" class="text-sky-600 text-md">&lt; К списку постов</router-link></span>
    <h1 v-if="post" class="flex flex-row items-center text-3xl text-black dark:text-white">
      {{post.title}} 
      <router-link class="inline" :to="{name: 'post.edit', params: {id: post.id}}" data-tooltip-target="tooltip-edit">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-2.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
        </svg>
      </router-link>
      <div id="tooltip-edit" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Редактировать публикацию
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="mt-4">
      <ErrorComponent v-if="error"/>
      <LoadingComponent v-if="loading"/>

      <div v-if="post && !loading && !error">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div class="mb-4">
              <span class="text-blue-700">Изображение</span>
              <img class="w-3/4 md:w-2/5 mx-auto" :src="'https://prime-spb.com/uploads/portfolio_thumb/'+post.thumb_img">
            </div>
            <div class="mb-4">
              <span class="text-blue-700">Статус</span>
              <div v-if="post.is_published" class="flex flex-row items-center text-black text-md font-bold dark:text-green-500">
                <span class="cursor-pointer" @click="changeStatus()">Опубликован</span>
                <button  data-tooltip-target="tooltip-ispublished" type="button"><svg class="w-4 h-4 ml-2 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg><span class="sr-only">Show information</span></button>
              </div>
              <div v-else class="flex flex-row items-center text-black text-md font-bold dark:text-yellow-400">
                <span class="cursor-pointer" @click="changeStatus()">Не опубликован</span>
                <button  data-tooltip-target="tooltip-ispublished" type="button"><svg class="w-4 h-4 ml-2 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg><span class="sr-only">Show information</span></button>
              </div>

              <div id="tooltip-ispublished" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                  Нажмите на статус, чтобы изменить статус
                  <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>



            <div class="mb-4">
              <span class="text-blue-700">Категория</span>
              <div class="text-black text-md font-bold dark:text-white">
                <router-link :to="{name: 'category.show', params: {id: post.category.id}}">{{post.category.title}}</router-link>
              </div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">Заголовок статьи</span>
              <div class="text-black text-md font-bold dark:text-white">{{post.title}}</div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">Описание статьи</span>
              <div class="text-black text-md font-bold dark:text-white">{{post.description}}</div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">meta-описание</span>
              <div v-if="post.ceo_description" class="text-black text-md font-bold dark:text-white">{{post.ceo_description}}</div>
              <div v-else>
                <div class="text-red-700 text-md font-bold dark:text-red-700">
                  Отсутствует
                  <button data-popover-target="popover-description" data-popover-placement="right" type="button"><svg class="w-4 h-4 ml-2 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Show information</span>
                  </button>
                </div>
                <div data-popover id="popover-description" role="tooltip" class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                  <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                      <h3 class="font-semibold text-gray-900 dark:text-white">Что это значит?</h3>
                  </div>
                  <div class="px-3 py-2">
                      <p>Это значит что meta-описание не указано, а значит для страницы поста используется мета-описание по умолчанию.</p>
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              
            </div>
            <div class="mb-4">
              <span class="text-blue-700">HTML</span>
              <div class="text-black text-md dark:text-white" v-html="post.html"></div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">ЧПУ</span>
              <div class="text-black text-md font-bold dark:text-white">{{post.url}}</div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">Адрес</span>
              <div class="text-black text-md font-bold dark:text-white">{{post.loc}}</div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">Дата выполнения</span>
              <div class="text-black text-md font-bold dark:text-white">{{post.dte}}</div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">Объем работ (м<sup>2</sup>)</span>
              <div class="text-black text-md font-bold dark:text-white">{{post.vol}}</div>
            </div>
          </div>
          <!-- ... -->
          <div>
            <h2 class="text-xl">Изображения галереи</h2>
            <!-- /.text-xl -->
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
              <div class="relative" v-if="post.images.length > 0" v-for="(image, index) in post.images" :key="index">
                <img class="h-auto max-w-full rounded-lg" :src="'https://prime-spb.com/uploads/portfolio_images/thumbnail/'+image.src+'.png'">

                <span @click.prevent="removeImage(image.id)" class="absolute top-1 right-1 px-2 rounded-lg cursor-pointer dark:text-white dark:bg-gray-800">X</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent'
import ErrorComponent from '@/components/ErrorComponent'
import {initPopovers, initTooltips} from 'flowbite'
export default {

  name: 'SitePost',

  props: ['id'],

  components: {
    LoadingComponent,
    ErrorComponent
  },

  data() {
    return {
      loading: true,
      error: false,
      post: null
    };
  },

  mounted(){
    this.getPost()
  },

  updated(){
    initPopovers()
    initTooltips()
  },

  methods: {
    getPost() {
      this.loading = true
      this.post = null
      this.error = false
      axios
          .get('post/'+this.id)
          .then(data=>{
            this.post = data.data.data
          })
          .catch(err=>{
            this.error = true
            console.log(err)
          })
          .finally(()=>{this.loading = false})
    },

    removeImage(id){
      this.$swal.fire({
        title: 'Вы в порядке?',
        html: "Вы точно уверены, что хотите удалить изображение? <br><br>Это действие <b>необратимо</b>.",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Не'
      }).then((res) => {
        if (res.isConfirmed) {
          
          this.$swal.fire({
            title: 'Еще не поздно передумать',
            html: "Еще раз напомню, что фото удалится <b>навсегда</b> и это действие <b>необратимо</b>.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#6e7881',
            confirmButtonText: 'Да, удалить',
            cancelButtonText: 'Не, я передумал(а)'
          }).then((result) => {
            if (result.isConfirmed) {
              this.loading = true
              axios
                  .delete('post/removeImage/'+id)
                  .then(data=>{
                    if (data.data.ok) {
                      new Noty({
                        type: 'success',
                        text: 'Операция выполнена'
                      }).show()
                      this.getPost()
                    }
                  })
                  .catch(err=>{
                    new Noty({
                      type: 'error',
                      text: 'Ошибка удаления'
                    }).show()
                  })
                  .finally(()=>{this.loading = false})
            }
          })

        }
      })
    },

    changeStatus(){
      let current = this.post.is_published;
      let to = (current == 1) ? 0 : 1;

      axios
          .post('post/'+this.id+'/updateStatus', {is_published: to})
          .then(data=>{
            this.post.is_published = to
            new Noty({
              type: 'success',
              text: 'Статус изменен'
            }).show()
          })
          .catch(err=>{
            console.log(err)
            new Noty({
              type: 'error',
              text: 'Не удалось изменить статус'
            }).show()
          })
    }
  },
};
</script>

<style lang="css" scoped>
</style>
