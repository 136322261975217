<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Пользователи</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="mt-4">
      <ErrorComponent v-if="error"/>
      <LoadingComponent v-if="loading"/>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div v-if="currentUser" class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="flex justify-end px-4 pt-4">
              <button id="dropdownButton" data-dropdown-toggle="dropdown" class="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5" type="button">
                  <span class="sr-only">Open dropdown</span>
                  <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path></svg>
              </button>
                <!-- Dropdown menu -->
              <div id="dropdown" class="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                  <ul class="py-2" aria-labelledby="dropdownButton">
                  <li>
                      <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Редактировать</a>
                  </li>
                  <li>
                      <a href="#" @click.prevent="changePassModal.show()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Изменить пароль</a>
                  </li>
                  </ul>
              </div>
            </div>
            <div class="flex flex-col items-center pb-10">
              <img class="w-24 h-24 mb-3 rounded-full shadow-lg" src="@/assets/avatar.jpg" alt="User avatar"/>
              <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{{currentUser.name}}</h5>
              <span class="text-sm text-gray-500 dark:text-gray-400">{{currentUser.email}}</span>
              <span v-if="currentUser.isAdmin" class="bg-pink-100 text-pink-800 text-xs font-medium my-2.5 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">Админ</span>
              <span class="text-sm text-gray-500 dark:text-gray-400">
                Создан {{moment(currentUser.created_at).fromNow()}}, обновлён {{moment(currentUser.updated_at).fromNow()}}
              </span>
            </div>

            <div class="mt-4 p-2.5">
              <h2 class="text-xl">Роли пользователя</h2>
              <!-- /.text-2xl -->
            </div>
            <!-- /.mt-4 -->
            <div class="grid grid-cols-2 gap-1 p-2.5" v-if="roles">
              <div class="flex items-center mb-4" v-for="role in roles" :key="role.id">
                <input :id="'role-checkbox-'+role.id" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label :for="'role-checkbox-'+role.id" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{role.name}}</label>
              </div>
            </div>
            <!-- /.grid -->
        </div>
        </div>
        <div>
          <div class="text-right mb-4">
            <router-link :to="{name: 'service.user_create'}" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Добавить пользователя</router-link>
          </div>
          <TransitionGroup class="divide-y divide-gray-200 dark:divide-gray-700" name="list" tag="ul" v-if="users && !loading">
            <li class="py-3 px-2 sm:py-4 cursor-pointer hover:dark:bg-gray-800 hover:rounded-md" v-for="(user, index) in users" :key="index">
              <a href="#" class="flex items-center space-x-4 no-underline">
                <div class="flex-shrink-0">
                  <img class="w-12 h-12 rounded-full" src="@/assets/avatar.jpg">
                </div>
                <div class="flex-1 min-w-0">
                  <p class="text-md font-medium text-gray-900 truncate dark:text-white">
                    {{user.name}}
                  </p>
                  <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                    {{user.email}}
                  </p>
                  <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                    Последний вход {{momentU(user.last_login).fromNow()}} <span v-if="user.app_name">({{user.app_name}}, v{{user.app_v}}, {{user.ip}})</span>
                  </p>
                </div>
                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  <span v-if="user.isAdmin" class="bg-pink-100 text-pink-800 text-xs font-medium my-2.5 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">Админ</span> 
                </div>
              </a>
            </li>
          </TransitionGroup>
        </div>
      </div>
      <!-- /.grid -->      
    </div>



  <div id="changePasswordModal" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                  Сменить пароль
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" @click="changePassModal.hide()">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6">
              <div class="mb-4">
                <label for="old_pass" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Старый пароль</label>
                <input type="text" id="old_pass" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="pass.old_password" required>
              </div>
              <div class="mb-4">
                <label for="old_pass" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Новый пароль</label>
                <input type="text" id="old_pass" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="pass.password" required>
              </div>
            </div>
            <!-- Modal footer -->
            <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" @click="savePassword()">Сохранить</button>
                <button type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" @click="changePassModal.hide()">Decline</button>
            </div>
        </div>
    </div>
</div>

  </div>
  <!-- /.container mx-auto p-4 -->
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent'
import ErrorComponent from '@/components/ErrorComponent'
import {HandleError} from '@/helpers/errorHandler.js'
import {initDropdowns} from 'flowbite'
import moment from 'moment';
export default {

  name: 'ServiceUsers',
  components:{
    LoadingComponent,
    ErrorComponent
  },
  data() {
    return {
      roles: null,
      currentUserRoles: [],
      loading: false,
      error: false,
      currentUser: null,
      users: null,
      pass: {
        old_password: null,
        password: null
      },
      changePassModal: null
    };
  },
  mounted(){
    initDropdowns()
    this.getMe()
    this.getUsers()
    this.iniMod()
    this.getRoles()
    // axios.get(this.$store.state.apiBaseURL + 'user/me')
    //       .then(data=>{
    //         this.currentUser = data.data.data
    //         console.log(data.data)
    //       })
    //       .catch(error=>{
    //         console.log(error);
    //       })
  },

  updated(){
    initDropdowns()
  },

  methods: {
    getMe(){
      this.loading = true
      this.currentUser = null
      axios
          .get('user/me')
          .then(data=>{
            this.currentUser = data.data.data
            console.log(data.data)
          })
          .catch(error=>{
            console.log(error);
          })
          .finally(()=>{
            this.loading = false
          })
    },
    getUsers(){
      axios
          .get('user/all')
          .then(data=>{
            this.users = data.data.data.data;
          })
          .catch(err=>{
            console.log(err)
            new Noty({
              type: 'error',
              text: 'Не удалось получить список пользователей.'
            }).show()
          })
    },
    iniMod(){
      this.changePassModal = new Modal(document.getElementById('changePasswordModal'));
    },
    savePassword(){
      this.$progress.start()
      axios.post('user/changePassword', this.pass)
          .then(data=>{
            if (data.data.ok) {
              this.$progress.start()
              axios.post('user/refresh')
                    .then(data => {
                      if (data.data.access_token) {
                        localStorage.setItem('prime_token', data.data.access_token);
                        this.$store.commit('setToken', data.data.access_token);

                        this.pass = {};

                        new Noty({
                          type: 'success',
                          text: 'Пароль успешно изменен'
                        }).show();

                        this.changePassModal.hide()
                      }
                    })
                    .catch(error=>{
                      this.$swal.fire({
                        text: error.response.data.message,
                        imageUrl: '/img/tb.png',
                        imageWidth: '300px'
                      })
                      console.log(error)
                    })
                    .finally(()=>{
                      this.$progress.finish()
                    })
            }
          })
          .catch(error=>{
            this.$swal.fire({
              text: error.response.data.message,
              imageUrl: '/img/tb.png',
              imageWidth: '300px'
            })
            console.log(error)
          })
          .finally(()=>{this.$progress.finish()})
    },

    getRoles(){
      axios.get('user/role')
            .then(data=>{
              this.roles = data.data.data.data
            })
            .catch(err=>{
              HandleError(err, 'Ошибка при получении списка ролей: ')
            })
    },

    moment(e){
      moment.locale('ru');
      return moment(e);
    },
    momentU(e){
      moment.locale('ru');
      return moment.unix(e);
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
