<template>
  <div class="container mx-auto p-4">
    <span v-if="post" class="block py-3"><router-link :to="{name: 'post.show', params: {id: post.id}}" class="text-sky-600 text-md">&lt; К посту</router-link></span>
    <h1 v-if="post" class="flex flex-row items-center text-3xl text-black dark:text-white">
      {{post.title}}
    </h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div v-if="post">
      <button @click="savePost()" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
        <span v-if="!saveLoading">Сохранить</span>
        <div v-else>
          <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
          </svg>
          Loading...
        </div>
      </button>

      <button v-if="post && !post.mark_as_deleted" @click="markPostAsDeleted()" type="button" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900">Пометить на удаление</button>

      <button v-if="post && post.mark_as_deleted" @click="markPostAsDeleted()" type="button" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900">Снять пометку на удаление</button>


      <button v-if="$store.getters.isAdmin == true" @click="removePost()" type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Удалить пост</button>

    </div>

    <div class="mt-4">
      <ErrorComponent v-if="error" :errors="errors"/>
      <LoadingComponent v-if="loading"/>

      <div v-if="post && !loading && !error">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div class="mb-4">
              <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Заголовок</label>
              <input type="text" id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="post.title">
            </div>
            <div class="mb-4">
              <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Описание статьи</label>
              <textarea id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="post.description"></textarea>
            </div>
            <div class="mb-4">
              <label for="ceo_description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">meta-описание</label>
              <textarea id="ceo_description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="post.ceo_description"></textarea>
            </div>

            <div class="mb-4">
              <label for="html" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">HTML</label>
              <ckeditor :editor="editor" v-model="post.html" :config="editorConfig"></ckeditor>
            </div>

            <div class="mb-4">
              <span class="text-blue-700">ЧПУ</span>
              <div class="text-black text-md font-bold dark:text-white">{{post.url}}</div>
            </div>
            <div class="mb-4">
              <label for="loc" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Адрес</label>
              <input type="text" id="loc" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="post.loc">
            </div>
            <div class="mb-4">
              <label for="dte" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Дата выполнения</label>
              <VueDatePicker
                id="dte"
                v-model="post.dte"
                model-type="yyyy-MM-dd"
                format="dd.MM.yyyy"
                locale="ru"
                :dark="true"
                :enable-time-picker="false"
              />
            </div>
            <div class="mb-4">
              <label for="vol" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Объем м2</label>
              <input type="tel" id="vol" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="post.vol">
            </div>

            <div class="mb-4">
              <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" :true-value="1" :false-value="0" v-model="post.is_published">
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Опубликован</span>
              </label>
            </div>
            <!-- /.mb-4 -->
          </div>
          <!-- ... -->
          <div>
            <div class="flex flex-row justify-between items-center">
              <h2 class="text-xl">Изображения галереи</h2>

              <div class="flex flex-row items-center">
                <div>
                  <input @change.prevent="fileChange" id="newGalleryFile" type="file" accept="image/*" hidden multiple>
                  <label data-tooltip-target="tooltip-upload" for="newGalleryFile" class="flex items-center justify-center text-white bg-blue-700 rounded-full w-8 h-8 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800 cursor-pointer">
                    <svg aria-hidden="true" class="w-4 h-4 transition-transform group-hover:rotate-45" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                      <span class="sr-only">Upload image</span>
                  </label>
                  <div id="tooltip-upload" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                      Добавить изображение в галерею
                      <div class="tooltip-arrow" data-popper-arrow></div>
                  </div>
                </div>
                <button @click="keepImages()" :disabled="!saveImagesButton" data-tooltip-target="tooltip-save-gal" class="flex items-center justify-center text-white bg-blue-700 rounded-full w-8 h-8 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800 cursor-pointer ml-2.5 disabled:cursor-not-allowed disabled:dark:bg-blue-500 disabled:dark:hover:bg-blue-500">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                </button>
                <div id="tooltip-save-gal" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Сохранить последовательность
                    <div class="tooltip-arrow" data-popper-arrow></div>
                </div>
                
              </div>
            </div>

            <div v-if="uploadLoading != 0">
              <div class="text-center">
                <div role="status">
                  <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>

            <div v-if="!saveImagesButton" class="p-4 my-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
              Удерживайте и переместите фото для изменения положения в галерее.
            </div>
            <div v-if="saveImagesButton" class="p-4 my-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400" role="alert">
              Изменения не сохранены
            </div>
            
            
            <!-- /.text-xl -->
            <draggable v-if="post.images.length > 0" class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4" :list="post.images" @change="log" v-model="drag">
              <TransitionGroup name="list">
                <div class="relative" draggable="true" v-if="post.images.length > 0" v-for="(image, index) in post.images" :key="image.id">
                  <img class="h-auto max-w-full rounded-lg" :src="'https://prime-spb.com/uploads/portfolio_images/thumbnail/'+image.src+'.png'">

                  <span @click.prevent="removeImage(image.id)" class="absolute top-1 right-1 px-2 rounded-lg cursor-pointer dark:text-white dark:bg-gray-800">X</span>
                </div>
              </TransitionGroup>
              
            </draggable>
            <!-- <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
              
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent'
import ErrorComponent from '@/components/ErrorComponent'
import {initPopovers, initTooltips} from 'flowbite'
import VueDatePicker from '@vuepic/vue-datepicker';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru';
import { VueDraggableNext } from 'vue-draggable-next'
import {HandleError} from '@/helpers/errorHandler.js'
export default {

  name: 'SitePostEdit',
  props: ['id'],
  components: {
    LoadingComponent,
    ErrorComponent,
    ckeditor: CKEditor.component,
    VueDatePicker,
    draggable: VueDraggableNext,
  },

  data() {
    return {
      loading: true,
      saveImagesButton: false,
      saveLoading: false,
      error: false,
      errors: null,
      post: null,
      newImage: null,
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList', '|', 'blockQuote', '|','undo', 'redo'],

        bold: {
          options:{
            classes: ['text-bold']
          }
        }
      },

      uploadLoading: 0,
      drag: null
    };
  },

  mounted(){
    this.getPost()
  },

  updated(){
    initPopovers()
    initTooltips()
  },

  methods: {
    log(e){
      this.saveImagesButton = true
    },
    getPost() {
      this.saveImagesButton = false
      this.loading = true
      this.post = null
      this.error = false
      axios
          .get('post/'+this.id)
          .then(data=>{
            this.post = data.data.data
          })
          .catch(err=>{
            this.error = true
            console.log(err)
          })
          .finally(()=>{this.loading = false})
    },

    markPostAsDeleted(){
      axios
          .put('post/'+this.post.id, {
            mark_as_deleted: !this.post.mark_as_deleted,
            is_published: this.post.mark_as_deleted
          })
          .then(data=>{
            this.getPost()
          })
          .catch(err=>{
            HandleError(err)
          })
    },

    removeImage(id){
      this.$swal.fire({
        title: 'Вы в порядке?',
        html: "Вы точно уверены, что хотите удалить изображение? <br><br>Это действие <b>необратимо</b>.",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Не'
      }).then((res) => {
        if (res.isConfirmed) {
          
          this.loading = true
          axios
              .delete('post/removeImage/'+id)
              .then(data=>{
                if (data.data.ok) {
                  new Noty({
                    type: 'success',
                    text: 'Операция выполнена'
                  }).show()
                  this.getPost()
                }
              })
              .catch(err=>{
                new Noty({
                  type: 'error',
                  text: 'Ошибка удаления'
                }).show()
              })
              .finally(()=>{this.loading = false})

        }
      })
    },

    fileChange(e){
      let images = e.target.files;
      

      if (images.length > 0) {
        this.uploadLoading = images.length;
        for (var i = images.length - 1; i >= 0; i--) {
          
          let fd = new FormData();
          let name = images[i].name;
          fd.append('image', images[i])
          fd.append('portfolio_post_id', this.post.id)

          axios
              .post('post/'+this.id+'/upload', fd)
              .then(()=>{
              })
              .catch(err=>{
                console.log(err)
                new Noty({
                  type: 'error',
                  text: 'Не удалось загрузить изображение '+name+'.'
                }).show()
              })
              .finally(()=>{
                this.uploadLoading--;

                if (this.uploadLoading == 0) {
                  e.target.value = null;
                  this.getPost();
                }
              })

        }
      }else{
        new Noty({
          type: 'warning',
          text: 'Не выбрано ни одного файла'
        }).show()
      }

    },

    savePost(){
      this.error = null
      this.error = false
      axios
          .post('post/'+this.id, this.post)
          .then(data=>{
            this.$router.push({name: 'post.show', params: {id: this.id}})
          })
          .catch(err=>{
            console.log(err)
            this.error = true
            this.errors = err?.response.data.errors
          })

    },

    keepImages(){
      let images = this.post.images;
      let done = 0;

      var $that = this;

      images.forEach((val, index) => {
        axios.put('image/'+val.id, {
          display: index
        })
        .then()
        .catch(err=>{
          HandleError(err)
        })
        .finally(()=>{
          done++

          if (done === images.length) {
            $that.getPost()
          }
        })
      })
    },

    removePost(){
      axios.delete('post/'+this.id)
          .then(data=>{
            this.$router.push({name: 'post.all', params: {id: this.id}})
          })
          .catch(err=>{
            HandleError(err)
          })
    }
  },
};
</script>

<style lang="scss">
  @import '../../node_modules/@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
  .dp__theme_dark {
    --dp-background-color: #374151;
    --dp-text-color: #ffffff;
    --dp-hover-color: #484848;
    --dp-hover-text-color: #ffffff;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #005cb2;
    --dp-primary-text-color: #ffffff;
    --dp-secondary-color: #a9a9a9;
    --dp-border-color: #2d2d2d;
    --dp-menu-border-color: #2d2d2d;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #737373;
    --dp-scroll-bar-background: #212121;
    --dp-scroll-bar-color: #484848;
    --dp-success-color: #1e40af;
    --dp-success-color-disabled: #428f59;
    --dp-icon-color: #959595;
    --dp-danger-color: #e53935;
    --dp-highlight-color: rgba(0, 92, 178, 0.2);
  }

  .list-enter-active,
  .list-leave-active {
    transition: transform 1s;
  }
  .list-enter-from,
  .list-leave-to {
    transition: transform 1s;
  }
</style>
