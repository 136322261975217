<template>
<!-- <nav class="tw-bg-white tw-border-gray-200 dark:tw-bg-gray-900">
  <div class="tw-max-w-screen-xl tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-mx-auto tw-p-4">
    <router-link :to="{name: 'index'}" class="tw-flex tw-items-center">
        <img src="@/assets/logo_prime_spb.svg" class="tw-h-8 tw-mr-3" alt="Prime-Admin Logo" />
        <span class="tw-self-center tw-text-2xl tw-font-semibold tw-whitespace-nowrap dark:tw-text-white">Admin</span>
    </router-link>
  </div>
</nav> -->
</template>

<script>
export default {
  name: 'DefaultNavbar',

  data() {
    return {

    };
  },
};
</script>

<style lang="sass" scoped>
</style>
