<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Лог</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="mt-4 mb-4">
      <button @click="clearLog()" type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Очистить лог</button>

      <button @click="getLog()" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Обновить</button>
    </div>
    <!-- /.mt-4 mb-4 -->


    <pre class="bg-gray-800 p-4 rounded-xl md:rounded max-w-full overflow-x-auto max-h-96">
      {{log}}
    </pre>
    
  </div>
</template>

<script>
import {HandleError} from '@/helpers/errorHandler.js'
export default {

  name: 'Log',

  data() {
    return {
      log: null
    };
  },

  mounted(){
    this.getLog()
  },

  methods: {
    getLog() {
      this.$progress.start();
      this.log = null
      axios
          .get('service/log')
          .then(data=>{
            this.log = data.data
          })
          .catch(err=>{
            HandleError(err, 'Ошибка получения лога: ')
          })
          .finally(()=>{
            this.$progress.finish();
          })
    },

    clearLog(){
      axios
          .get('service/log/clear')
          .then(data=>{
            new Noty({
              type: 'success',
              text: 'Очищено'
            }).show()

            this.getLog()
          })
          .catch(err=>{
            HandleError(err, 'Ошибка очистки лога: ')
          })
    }
  },
};
</script>

<style lang="css" scoped>
</style>
