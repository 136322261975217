<template>
  <div class="bg-gray-800 text-white rounded md:rounded-xl p-4 my-4 flex flex-col">
    <div class="flex flex-row justify-between items-center">
      <h1 class="text-xl">Последные callback-ответы</h1>

      <a href="#" @click.prevent="getData()">
        <svg :class="{'animate-spin': loading}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
      </a>
    </div>

    <ul role="list" class="max-w-full divide-y divide-gray-200 dark:divide-gray-700 mt-4" v-if="status_messages">
      <li class="py-3 sm:py-4" v-for="(item, index) in status_messages" :key="index">
        <div class="flex items-center space-x-3">
          <div class="flex-1 min-w-0">
            <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
              {{item.user_id}}
            </p>
            <p class="text-sm text-gray-500 truncate dark:text-gray-400">
              {{item.username}}
            </p>
            <p class="text-sm text-gray-500 truncate dark:text-gray-400" :title="moment(item.updated_at).format('DD.MM.YYYY HH:mm:ss')">
              {{moment(item.updated_at).fromNow()}}
            </p>
          </div>
          <span class="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
            <span class="w-2 h-2 mr-1 bg-green-500 rounded-full"></span>
            ok
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment'
export default {

  name: 'LastCallbackComponent',

  data() {
    return {
      loading: true,
      status_messages: null
    };
  },

  mounted(){
    this.getData()
  },

  methods: {
    getData() {
      this.loading = true
      this.status_messages = null
      axios
        .get('telegram/test/status')
        .then(data=>{
          this.status_messages = data.data.data
        })
        .finally(()=>{
          this.loading = false
        })
    },
    moment(e){
      moment.locale('ru');
      return moment(e);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
