<template>
  <div class="container mx-auto p-4">
    <span class="block py-3"><router-link :to="{name: 'category.all'}" class="text-sky-600 text-md">&lt; К списку категорий</router-link></span>
    <h1 v-if="category" class="flex flex-row items-center text-3xl text-black dark:text-white">
      {{category.title}} 
      <router-link class="inline" :to="{name: 'category.edit2', params: {id: category.id}}" data-tooltip-target="tooltip-edit">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-2.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
        </svg>
      </router-link>
      <div id="tooltip-edit" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Редактировать категорию
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="mt-4">
      <ErrorComponent v-if="error"/>
      <LoadingComponent v-if="loading"/>

      <div v-if="category && !loading && !error">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div class="mb-4">
              <span class="text-blue-700">Изображение</span>
              
              <img class="w-3/4 md:w-2/5 mx-auto" :src="'https://prime-spb.com/uploads/portfolio_categories/'+category.img">
            </div>
            <div class="mb-4">
              <span class="text-blue-700">Статус</span>
              <div v-if="category.is_published" class="flex flex-row items-center text-black text-md font-bold dark:text-green-500">
                <span class="cursor-pointer" @click="changeStatus()">Опубликован</span>
                <button  data-tooltip-target="tooltip-ispublished" type="button"><svg class="w-4 h-4 ml-2 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg><span class="sr-only">Show information</span></button>
              </div>
              <div v-else class="flex flex-row items-center text-black text-md font-bold dark:text-yellow-400">
                <span class="cursor-pointer" @click="changeStatus()">Не опубликован</span>
                <button  data-tooltip-target="tooltip-ispublished" type="button"><svg class="w-4 h-4 ml-2 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg><span class="sr-only">Show information</span></button>
              </div>

              <div id="tooltip-ispublished" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                  Нажмите на статус, чтобы изменить статус
                  <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">Название категории</span>
              <div class="text-black text-md font-bold dark:text-white">{{category.title}}</div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">Описание категории</span>
              <div class="text-black text-md font-bold dark:text-white">{{category.description}}</div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">meta-описание</span>
              <div v-if="category.meta_description" class="text-black text-md font-bold dark:text-white">{{category.meta_description}}</div>
              <div v-else>
                <div class="text-red-700 text-md font-bold dark:text-red-700">
                  Отсутствует
                  <button data-popover-target="popover-description" data-popover-placement="right" type="button"><svg class="w-4 h-4 ml-2 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Show information</span>
                  </button>
                </div>
                <div data-popover id="popover-description" role="tooltip" class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                  <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                      <h3 class="font-semibold text-gray-900 dark:text-white">Что это значит?</h3>
                  </div>
                  <div class="px-3 py-2">
                      <p>Это значит что meta-описание не указано, а значит для страницы используется мета-описание по умолчанию.</p>
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              
            </div>
            <div class="mb-4">
              <span class="text-blue-700">ЧПУ</span>
              <div class="text-black text-md font-bold dark:text-white">{{category.url}}</div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">Создана</span>
              <div class="text-black text-md font-bold dark:text-white">{{moment(category.created_at).fromNow()}}</div>
            </div>
            <div class="mb-4">
              <span class="text-blue-700">Изменена</span>
              <div class="text-black text-md font-bold dark:text-white">{{moment(category.updated_at).fromNow()}}</div>
            </div>
          </div>
          <!-- ... -->
          <div>
            <h1 class="text-3xl mb-4">Публикации</h1>
            <!-- /.text-3xl -->
            <div class="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4">
              <PostItemComponent v-if="category && category.posts" v-for="(item, index) in category.posts" :post="item" :key="index"></PostItemComponent>
            </div>
          </div>
        </div>
      </div>
    </div>



    <router-view></router-view>
  </div>
</template>


<script>
import {initPopovers, initTooltips} from 'flowbite'
import moment from 'moment';
import PostItemComponent from '@/components/post/PostItemComponent'
export default {
  props: ['id'],
  name: 'SiteCategory',

  components: {
    PostItemComponent
  },

  data() {
    return {
      loading: true,
      error: false,
      category: null
    };
  },

  mounted(){
    this.getCategory()
  },

  updated(){
    initPopovers()
    initTooltips()
  },

  methods: {
    getCategory() {
      this.loading = true
      this.error = false
      axios
          .get('category/'+this.id)
          .then(data=>{
            this.category = data.data.data
          })
          .catch(err=>{
            console.log(err)
            this.error = true
          })
          .finally(()=>{
            this.loading = false
          })
    },

    changeStatus(){
      axios.put('category/'+this.id, {
        is_published: Number(!this.category.is_published)
      })
      .then(data => {
        this.category.is_published = Number(!this.category.is_published)
      })
    },

    moment(e){
      moment.locale('ru')
      return moment(e)
    }
  },
};
</script>

<style lang="css" scoped>
</style>
