import { createApp } from 'vue'
import App from './App.vue'

import VueSweetalert2 from 'vue-sweetalert2';
import '@sweetalert2/theme-dark/dark.scss';

import Vue3Progress from "vue3-progress";
const Vue3Progressoptions = {
  position: "fixed",
  height: "3px",
  // color: "<Your Color>",
};

import './registerServiceWorker'
import router from './router'
import store from './store'

require('@/plugins/noty.js')
require('@/plugins/axios.js')

const DEFAULT_TITLE = 'PrimeAdmin';

import 'flowbite'

const primeadmin = createApp(App)
                                .use(store)
                                .use(router)
                                .use(VueSweetalert2)
                                .use(Vue3Progress, Vue3Progressoptions)

primeadmin.config.globalProperties.$app_name = require('../package.json').name
primeadmin.config.globalProperties.$app_v = require('../package.json').version
primeadmin.config.globalProperties.$app_date = require('../package.json').build_date

primeadmin.mount('#__primeadmin')