<template>
  <div class="fixed w-full h-full top-0 left-0 z-50 bg-gray-900 flex justify-center items-center">
    <div class="flex flex-col items-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 mb-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
      </svg>
      <h2 class="text-2xl">В разработке</h2>
      <p class="text-xl text-gray-400 my-4 text-center p-2.5">
        В настоящий момент этот раздел улучшается. Он будет доступен сразу после окончания обновления.
      </p>
      <!-- /.text-xl text-gray-400 -->
      <a class="font-bold" href="#" @click.prevent="$router.go(-1)">Вернуться назад</a>
      <!-- /.text-2xl -->
    </div>
    <!-- /.flex flex-col -->
  </div>
  <!-- /.fixed -->
</template>

<script>
export default {

  name: 'LockComponent',

  data() {
    return {

    };
  },
};
</script>

<style lang="css" scoped>
</style>
