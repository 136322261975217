<template>
  <div class="container mx-auto p-4">
    <span class="block py-3"><a href="#" @click.prevent="$router.go(-1)" class="text-sky-600 text-md">&lt; Назад</a></span>
    <h1 class="text-3xl text-black dark:text-white">Почтовая рассылка <span v-if="email">{{email.email}}</span></h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div v-if="email && !email.is_active" class="flex items-center p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
      <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span class="sr-only">Info</span>
      <div>
        <span class="font-medium">Внимание!</span> Пользователь запретил отправлять ему сообщения.
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4" v-if="email">
      <div>
        <h2 class="text-xl mb-4">Инфо</h2>
        <!-- /.text-2xl -->
        <div class="flex flex-col mb-4">
          <span class="text-blue-700">id</span>
          {{email.id}}
        </div>
        <div class="flex flex-col mb-4">
          <span class="text-blue-700">Имя</span>
          {{email.name}}
        </div>
        <div class="flex flex-col mb-4">
          <span class="text-blue-700">E-Mail</span>
          {{email.email}}
        </div>
        <div class="flex flex-col mb-4">
          <span class="text-blue-700">Компания</span>
          {{email.company || 'n\\d'}}
        </div>
        <div class="flex flex-col mb-4">
          <span class="text-blue-700">Создано</span>
          {{email.created_at}}
        </div>
        <div class="flex flex-col mb-4">
          <span class="text-blue-700">Обновлено</span>
          {{email.updated_at}}
        </div>
      </div>
      <div>
        <h2 class="text-xl mb-4">Отправленные письма</h2>

        <div v-for="mail in email.emails" class="flex flex-col rounded p-4 mb-4 md:rounded-xl bg-gray-800">
          <strong>Письмо от {{moment(mail.created_at).format("DD.MM.YYYY HH:mm")}}</strong>
          <div class="flex flex-row text-gray-500 mt-4">
            <span class="text-blue-700 mr-2">Отправитель: </span>
            {{mail.from}}
          </div>
          <div class="flex flex-row text-gray-500">
            <span class="text-blue-700 mr-2">Тип: </span>
            {{mail.type}}
          </div>
          <div class="flex flex-row text-gray-500">
            <span class="text-blue-700 mr-2">Шаблон: </span>
            {{mail.template}}
          </div>
          <div class="flex flex-row text-gray-500">
            <span class="text-blue-700 mr-2">UID: </span>
            {{mail.uid}}
          </div>
          <div class="flex flex-row text-gray-500" v-if="mail.unsubscribe">
            <span class="text-red-700 mr-2">Отписан</span>
          </div>
          <a :href="'https://prime-spb.com/email/'+mail.uid" target="_blank" class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1.5 mt-2 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 text-center ">Открыть письмо в браузере</a>
        </div>
        <!-- /.flex rounded rounded-xl -->
      </div>
    </div>
    <!-- /.grid -->
  </div>
</template>

<script>
import moment from 'moment'
export default {

  name: 'MailingListEmail',

  props: ['id'],

  data() {
    return {
      email: null
    };
  },

  mounted(){
    this.getData()
  },

  methods: {
    getData() {
      axios.get('emails/email/'+this.id)
            .then(data=>{
              this.email = data.data.data
            })
    },

    moment(e){
      return moment(e);
    }
  },
};
</script>

<style lang="css" scoped>
</style>
