<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Роли пользователей</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <h2 class="text-xl">Добавить роль</h2>
        <!-- /.text-xl -->

        <form @submit.prevent="createRole()">
          <div class="mb-4 mt-4">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Role name</label>
            <input type="text" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="updatePost" v-model="newRole.name">
          </div>
          <div class="mb-4">
            <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Описнаие</label>
            <input type="text" id="description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Права на обновление поста" v-model="newRole.description">
          </div>
          <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Сохранить</button>
        </form>
      </div>

      <div>
        <h2 class="text-xl">Роли в системе</h2>
        <!-- /.text-xl -->
        <div class="relative overflow-x-auto mt-4">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-4 py-1.5">
                  name
                </th>
                <th scope="col" class="px-4 py-1.5">
                  Описание
                </th>
                <th scope="col" class="px-4 py-1.5">
                  
                </th>
              </tr>
            </thead>
            <tbody v-if="roles">
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="(role, index) in roles" :key="index">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {{role.name}}
                </th>
                <td class="px-6 py-4">
                  {{role.description}}
                </td>
                <td class="px-6 py-4">
                  .
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- /.mt-4 -->
  </div>
  <LockComponent></LockComponent>
</template>

<script>
import {HandleError} from '@/helpers/errorHandler.js'
import LockComponent from '@/components/LockComponent'
export default {

  name: 'Roles',

  components: {
    LockComponent
  },

  data() {
    return {
      roles: null,
      newRole: {

      }
    };
  },

  created(){
    this.getRoles();
  },

  methods: {
    getRoles(){
      axios.get('user/role')
            .then(data=>{
              this.roles = data.data.data.data
            })
            .catch(err=>{
              HandleError(err, 'Ошибка при получении списка ролей: ')
            })
    },
    createRole() {
      axios.post('user/role', this.newRole)
            .then(data=>{
              this.newRole = {};
              this.getRoles();
              new Noty({
                type: 'success',
                text: 'Роль создана.'
              }).show();
            })
            .catch(err=>{
              HandleError(err, 'Ошибка при создании роли: ')
            })
    },
  },
};
</script>

<style lang="css" scoped>
</style>
