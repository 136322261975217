<template>
  <vue3-progress />
  <AdminNavbar v-if="this.$store.getters.isLoggedIn" :key="$route.path"/>
  <DefaultNavbar v-if="!this.$store.getters.isLoggedIn" :key="$route.path"/>
  <div v-if="!onLine" class="flex p-4 mb-4 mt-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
    <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
    <span class="sr-only">Info</span>
    <div>
      Нет соединения с интернетом.
    </div>
  </div>

  <div class="container mx-auto">
    <div v-if="$store.getters.debug" class="flex items-center p-4 mb-4 mt-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
      <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span class="sr-only">Info</span>
      <div>
        <span class="font-medium">Внимание!</span> Debug mode is on.
      </div>
    </div>
  </div>
  <!-- /.container -->

  

  <router-view :key="$route.path"></router-view>

</template>

<script>
  import AdminNavbar from '@/components/navbars/AdminNavbar'
  import DefaultNavbar from '@/components/navbars/DefaultNavbar'
  import Preloader from '@/components/Preloader'
  export default {
    components: {
      AdminNavbar,
      DefaultNavbar,
      Preloader
    },
    // setup () {
    //   useMeta({
    //     title: ''
    //   })
    // },

    data() {
      return {
        onLine: navigator.onLine ? true : false
      };
    },

    mounted() {
      this.$progress.finish();
      this.getStatus()
      console.log('Welcome to ' + this.$app_name + ' v'+this.$app_v + '! :)');
    },

    created() {
      this.$progress.start();

      this.$router.beforeEach((to, from, next) => {
        this.$progress.start();
        next();
      });

      this.$router.afterEach((to, from) => {
        this.$progress.finish();
      });

      //this.getStatus()

    },

    updated(){
      this.getStatus()
    },

    methods: {
      getStatus() {
          axios
            .get('service/status')
            .then(data=>{
              this.$store.dispatch('debug', data.data.data.app.debug)
            })
      },
    },
  }
</script>

<style lang="scss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
