<template>
  
  <div class="container mx-auto p-4">
    <h1 class="text-3xl text-black dark:text-white">Даш</h1>
    <!-- /.text-3xl text-black dark:text-white -->

    <!-- <div class="flex p-4 mt-2 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
      <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
      <span class="sr-only">Info</span>
      <div>
        <span class="font-medium">Не дам!</span>
      </div>
    </div> -->

    <div class="grid grid-cols-1 md:grid-cols-2 gap-2 mt-4">
      
      <DashGraphComponent></DashGraphComponent>
      <div>
        <NoticeComponent></NoticeComponent>
        <LastVisitorsCompontnt></LastVisitorsCompontnt>
      </div>
      

    </div>
    <!-- /.flex flex-row w-full -->
 
  </div>
  <!-- /.container mx-auto p-4 -->

</template>

<script>
import moment from 'moment'
import DashGraphComponent from '@/components/dash/DashGraphComponent'
import LastVisitorsCompontnt from '@/components/dash/LastVisitorsCompontnt'
import NoticeComponent from '@/components/dash/NoticeComponent'
export default {

  name: 'Dash',

  components: {
    DashGraphComponent,
    LastVisitorsCompontnt,
    NoticeComponent
  },

  data() {
    return {
      statData: null
    };
  },

  mounted(){
    this.getStat();
  },

  methods: {
    getStat(){
      axios
          .get('service/stat')
          .then(data=>{
            this.statData = data.data.data;
          })
          .catch(err=>{
            console.log(err)
            new Noty({
              type: 'error',
              text: 'Ошибка при загрузке статистики'
            }).show()
          })
    },
    moment(e){
      moment.locale('ru');
      return moment(e);
    }

  }
};
</script>

<style lang="css" scoped>
</style>
