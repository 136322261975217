<template>
  <div class="container mx-auto p-4">
    <div class="flex flex-row justify-between items-center">
      <h1 class="text-3xl text-black dark:text-white">Категории</h1>
      <router-link :to="{name: 'category.create'}" data-tooltip-target="tooltip-create" class="flex items-center justify-center text-white bg-blue-700 rounded-full w-10 h-10 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
        <svg aria-hidden="true" class="w-6 h-6 transition-transform group-hover:rotate-45" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
        <span class="sr-only">Открыть страницу добавления</span>
      </router-link>
      <div id="tooltip-create" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Создать категорию
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </div>
    <!-- /.flex -->
    
    

    <div class="grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-4 mt-6">
      <CaregoryCardComponent v-if="categories" v-for="(item, index) in categories" :category="item" :key="index"/>
    </div>
    <!-- /.grid -->

  </div>
  <!-- /.container mx-auto p-4 -->
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent'
import ErrorComponent from '@/components/ErrorComponent'
import CaregoryCardComponent from '@/components/category/CaregoryCardComponent'
import moment from 'moment';
import {initTooltips} from 'flowbite'
export default {

  name: 'SiteCategories',

  components: {
    LoadingComponent,
    ErrorComponent,
    CaregoryCardComponent
  },

  data() {
    return {
      loading: true,
      error: false,
      categories: [],
      currentPage: 1,
      lastPage: 1
    };
  },

  mounted(){
    this.getCategories();
  },

  updated(){
    initTooltips()
  },

  methods: {
    getCategories(){
      this.loading = true;
      this.error = false;
      this.categories = [];



      axios.get('category?withPosts=1'+((this.currentPage == 1) ? '' : '&page='+this.currentPage))
           .then(data=>{
              if (data.data.ok) {
                this.currentPage = data.data.data.current_page;
                this.lastPage = data.data.data.last_page;
                this.categories = data.data.data.data;
              }
            })
           .catch(error=>{
            this.error = true;
            console.log(error);
           })
           .finally(()=>{
            this.loading = false
           })
    },

    nextPage(){
      let next = this.currentPage;
      next++;

      if (next <= this.lastPage) {
        this.currentPage++;
        this.getCategories()
      }
    },

    prevPage(){
      let prev = this.currentPage;
      prev--;

      if (prev > 0) {
        this.currentPage--;
        this.getCategories()
      }
    },

    moment(e){
      moment.locale('ru');
      return moment(e);
    }
  }
};
</script>

<style lang="css" scoped>
</style>
